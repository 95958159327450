var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useRef, useState } from 'react';
import { Form, Button, Modal, Switch, message, Input, Alert } from 'antd';
import { useAppContext } from 'stores/AppStore';
import { observer } from 'mobx-react-lite';
import { ResolutionSliderFormItem, ShapeRadioFormItem } from 'components/SharedComponents';
import { getMetadata, getRouteV2 } from 'service/DataService';
const exampleSuitabilityLayer = `[{
	"code": "airports",
	"score": 200
}, {
	"code": "faa_obstacles",
	"score": 200
}, {
	"code": "helipads",
	"score": 200
}, {
	"code": "railroad_lines",
	"score": 50
}, {
	"code": "schools",
	"score": 200
}, {
	"code": "prisons",
	"score": 200
}, {
	"code": "hospitals",
	"score": 200
}, {
	"code": "military_facilities",
	"score": 200
}, {
	"code": "police_stations",
	"score": 200
}, {
	"code": "eocs",
	"score": 200
}, {
	"code": "national_parks",
	"score": 200
}, {
	"code": "transmission_lines",
	"score": 5
}, {
	"code": "electric_substations",
	"score": 200
}, {
	"code": "wind_farms",
	"score": 200
}, {
	"code": "power_plants",
	"score": 200
}, {
	"code": "fcc_asr",
	"score": 200
}, {
	"code": "mtr",
	"score": 5
}, {
	"code": "population_daytime",
	"score": 200,
	"where": [">", "population", 0]
}, {
	"code": "roads",
	"alias": "low_traffic_road",
	"score": 5,
	"highways": ["OR", ["=", "highway", "residential"],
		["=", "highway", "unclassified"],
		["=", "highway", "service"],
		["=", "highway", "track"],
		["=", "highway", "bus_glideway"],
		["=", "highway", "escape"],
		["=", "highway", "busway"]
	]
}, {
	"code": "roads",
	"alias": "medium_traffic_road",
	"score": 50,
	"where": ["OR", ["=", "highway", "secondary"],
		["=", "highway", "tertiary"],
		["=", "highway", "secondary_link"],
		["=", "highway", "tertiary_link"],
		["=", "highway", "road"]
	]
}, {
	"code": "roads",
	"alias": "high_traffic_road",
	"score": 200,
	"where": ["OR", ["=", "highway", "motorway"],
		["=", "highway", "trunk"],
		["=", "highway", "primary"],
		["=", "highway", "motorway_link"],
		["=", "highway", "trunk_link"],
		["=", "highway", "primary_link"],
		["=", "highway", "living_street"],
		["=", "highway", "pedestrian"],
		["=", "highway", "footway"],
		["=", "highway", "bridleway"],
		["=", "highway", "steps"],
		["=", "highway", "path"],
		["=", "highway", "cycleway"],
		["=", "highway", "crossing"]
	]
}]`;
const parseSurfaceFile = (file) => __awaiter(void 0, void 0, void 0, function* () {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = () => {
            if (reader.result) {
                try {
                    const json = JSON.parse(reader.result);
                    if (typeof json === 'object' && json !== null) {
                        const record = json;
                        resolve(record);
                    }
                    else {
                        throw new Error('Invalid JSON format');
                    }
                }
                catch (error) {
                    reject(error);
                }
            }
        };
    });
});
const RouteDemoV2 = () => {
    const [form] = Form.useForm();
    const store = useAppContext();
    const [routeLoading, setRouteLoading] = useState(false);
    const [metadataLoading, setMetadataLoading] = useState(false);
    const [metadataVisible, setMetadataVisible] = useState(false);
    const [featuresPayload, setFeaturesPayload] = useState(exampleSuitabilityLayer);
    const [selectedFile, setSelectedFile] = useState(null);
    const [surface, setSurface] = useState(null);
    const fileInputRef = useRef(null);
    useEffect(() => {
        function updateSurface() {
            return __awaiter(this, void 0, void 0, function* () {
                if (selectedFile) {
                    try {
                        const surface = yield parseSurfaceFile(selectedFile);
                        setSurface(surface);
                    }
                    catch (e) {
                        const readableMessage = 'Could not parse surface file: ';
                        message.error(readableMessage + e.message);
                        console.error(readableMessage, e);
                    }
                }
            });
        }
        updateSurface();
    }, [selectedFile, setSurface]);
    useEffect(() => {
        if (surface)
            message.success('JSON Loaded, rendering surface...');
        store.mapStore.renderData(surface, 'FILE_SURFACE', store.operationStore.operation.resolution);
    }, [surface]);
    const handleOnFinish = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const mp = store.mapStore.getMultiPoint();
        if (mp.coordinates.length < 2) {
            message.error('Please add at least two points');
            return;
        }
        let features = {};
        if (featuresPayload) {
            try {
                features = JSON.parse(featuresPayload);
            }
            catch (e) {
                message.error(`Suitable features payload invalid: ${e.toString()}`);
                console.error(e);
                return;
            }
        }
        const sketchGeometry = (_a = store.mapStore.sketch) === null || _a === void 0 ? void 0 : _a.features[0].geometry;
        const payload = {
            waypoints: mp,
            geometry: sketchGeometry,
            resolution: store.operationStore.operation.resolution,
            features,
            surface,
        };
        setRouteLoading(true);
        try {
            // UI Route response handler
            const result = yield getRouteV2(payload, store.accessToken);
            if (result.statusCode >= 400) {
                message.error(result.message);
                console.error(result);
                return;
            }
            store.mapStore.renderData(result === null || result === void 0 ? void 0 : result.data, 'ROUTEV2', store.operationStore.operation.resolution);
            store.mapStore.setMapLock(true);
        }
        catch (e) {
            message.error(e.toString());
            console.error(e);
        }
        finally {
            setRouteLoading(false);
        }
    });
    const fetchMetadata = () => __awaiter(void 0, void 0, void 0, function* () {
        setMetadataLoading(true);
        try {
            const result = yield getMetadata(store.accessToken);
            if (result.statusCode >= 400) {
                message.error(result.message);
                console.error(result);
                return;
            }
            store.setMetadata(result.data);
            setMetadataVisible(true);
        }
        catch (e) {
            message.error(e.toString());
            console.error(e);
        }
        finally {
            setMetadataLoading(false);
        }
    });
    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Alert, { style: { marginBottom: '1rem' }, showIcon: true, message: 'In beta testing', description: 'This demo may be unstable', type: 'warning' }),
        React.createElement(Form, { className: 'compressed-form', form: form, layout: 'vertical', style: { width: '100%' }, onFinish: handleOnFinish, initialValues: {} },
            React.createElement(ResolutionSliderFormItem, null),
            React.createElement(Form.Item, { label: 'Suitable features payload' },
                React.createElement(Input.TextArea, { defaultValue: featuresPayload, onChange: e => setFeaturesPayload(e.target.value) })),
            React.createElement(Form.Item, { label: 'Add way points' },
                React.createElement(Switch, { checked: !store.mapStore.mapLock, onChange: () => {
                        // toggle the map lock for adding points
                        store.mapStore.toggleMapLock();
                    } }),
                React.createElement(ShapeRadioFormItem, { onClick: (e) => __awaiter(void 0, void 0, void 0, function* () {
                        // lock the map while sketching so we can't accidently drop points
                        store.mapStore.setMapLock(true);
                        yield store.mapStore.startSketch(e.target.defaultValue);
                    }), label: 'Shape' })),
            React.createElement(Form.Item, { label: 'Surface.json File' },
                React.createElement("input", { type: 'file', ref: fileInputRef, onChange: handleFileChange }),
                React.createElement("p", null,
                    "Selected file: ",
                    selectedFile && selectedFile.name,
                    " ")),
            React.createElement(Form.Item, { style: { margin: '15px 0 0 0' } },
                React.createElement(Button, { type: 'primary', htmlType: 'submit', loading: routeLoading }, "Submit"),
                React.createElement(Button, { type: 'primary', style: { marginLeft: '8px' }, onClick: () => {
                        if (fileInputRef.current)
                            fileInputRef.current.value = '';
                        setSelectedFile(null);
                        setSurface(null);
                        store.mapStore.clearEverything();
                    } }, "Clear")),
            React.createElement(Form.Item, { style: { margin: '15px 0 0 0' } },
                React.createElement(Button, { type: 'primary', loading: metadataLoading, onClick: fetchMetadata }, "Get metadata sources"))),
        React.createElement(Modal, { width: 750, title: 'Available metadata', visible: metadataVisible, onOk: () => setMetadataVisible(false), onCancel: () => setMetadataVisible(false) },
            React.createElement("table", null,
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", null, "Name"),
                        React.createElement("th", null, "Code"))),
                React.createElement("tbody", null, store.metadata.map((row) => (React.createElement("tr", { id: row.code, key: row.code },
                    React.createElement("td", null,
                        React.createElement("strong", null, row.name)),
                    React.createElement("td", null,
                        React.createElement("pre", { style: { textAlign: 'right' } }, row.code))))))))));
};
export default observer(RouteDemoV2);
