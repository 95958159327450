import 'normalize.css';
import 'antd/dist/antd.css';
import 'style/main.scss';
import React from 'react';
import { render } from 'react-dom';
import App from 'App';
import { Auth0Provider } from '@auth0/auth0-react';
import { config } from 'config';
function renderApp() {
    render(React.createElement(Auth0Provider, { domain: config.auth0Domain, clientId: config.auth0ClientID, redirectUri: window.location.origin },
        React.createElement(App, null)), document.getElementById('root'));
}
renderApp();
