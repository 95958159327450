import React from 'react';
import { observer } from 'mobx-react-lite';
import { Divider, Select } from 'antd';
import { useAppContext } from 'stores/AppStore';
import RiskDemo from './RiskDemo';
import DensityDemo from './DensityDemo';
import WeatherDemo from './WeatherDemo';
import StrikeDemo from './StrikeDemo';
import SuitabilityDemo from './SuitabilityDemo';
import SurfaceDemo from './SurfaceDemo';
import ComplianceDemo from './ComplianceDemo';
import RouteDemo from './RouteDemo';
import RouteDemoV2 from './RouteDemoV2';
import GPSDemo from './GPSDemo';
import ElevationProfile from './ElevationProfile';
import SuitabilityDemoV2 from './SuitabilityDemoV2';
const { Option } = Select;
const demos = {
    cmRisk: {
        name: 'Cumulative Risk',
        component: React.createElement(RiskDemo, null),
    },
    surface: {
        name: 'Surface',
        component: React.createElement(SurfaceDemo, null),
    },
    suitability: {
        name: 'Suitability',
        component: React.createElement(SuitabilityDemo, null),
    },
    suitabilityV2: {
        name: 'Suitability v2',
        component: React.createElement(SuitabilityDemoV2, null),
    },
    popDensity: {
        name: 'Population Density',
        component: React.createElement(DensityDemo, null),
    },
    strikeChance: {
        name: 'Chance of Human Strike',
        component: React.createElement(StrikeDemo, null),
    },
    weather: {
        name: 'Weather',
        component: React.createElement(WeatherDemo, null),
    },
    oivCompliance: {
        name: 'OIV Compliance - Simulated',
        component: React.createElement(ComplianceDemo, null),
    },
    route: {
        name: 'Routing',
        component: React.createElement(RouteDemo, null),
    },
    routeV2: {
        name: 'RoutingV2',
        component: React.createElement(RouteDemoV2, null),
    },
    gps: {
        name: 'GPS',
        component: React.createElement(GPSDemo, null),
    },
    elevationProfile: {
        name: 'Elevation Profile',
        component: React.createElement(ElevationProfile, null),
    },
};
const DemoPicker = () => {
    const store = useAppContext();
    const changeComponent = (selectedKey) => {
        store.mapStore.clearEverything();
        const key = Object.keys(demos).find(key => key === selectedKey);
        if (key)
            store.setSelectedDemoKey(key);
        store.setElevationChartVisible(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("h3", null, "Select Demo:"),
        React.createElement(Select, { defaultValue: store.selectedDemoKey, style: { width: 250 }, listHeight: 350, onChange: changeComponent }, Object.keys(demos).map(key => (React.createElement(Option, { key: key, value: key }, demos[key].name)))),
        React.createElement(Divider, null),
        demos[store.selectedDemoKey].component));
};
export default observer(DemoPicker);
