var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { Form, Button, Modal, message } from 'antd';
import { useAppContext } from 'stores/AppStore';
import { getSnapshot } from 'mobx-state-tree';
import { observer } from 'mobx-react-lite';
import { getCumulativeSurface } from 'service/DataService';
import { UAVTypeSelectFormItem, UAVWeightSelectFormItem, LOSTypeSelectFormItem, PilotControlSelectFormItem, MaxAltitudeSliderFormItem, ResolutionSliderFormItem, ShapeRadioFormItem, } from 'components/SharedComponents';
const RiskDemo = () => {
    // The useForm hook is an AntD hook
    const [form] = Form.useForm();
    // Import the operation and map stores into this component
    // These stores are loaded from the context created in AppStore
    const { operationStore, mapStore, accessToken } = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    // Every input from the form that has a 'name' property will be
    // passed back to this values object
    const handleOnFinish = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!mapStore.sketch) {
            Modal.info({
                title: 'Please add sketch',
                content: 'Processing may not proceed until you complete a sketch',
            });
            return;
        }
        // Construct a function payload object
        const payload = Object.assign({
            geometry: mapStore.sketch.features[0].geometry,
        }, Object.assign({}, getSnapshot(operationStore.operation)));
        // Request the surface
        setIsLoading(true);
        const result = yield getCumulativeSurface(payload, accessToken).catch(ex => {
            message.error(ex.message);
        });
        mapStore.renderData(result === null || result === void 0 ? void 0 : result.data, 'RISK', operationStore.operation.resolution);
        setIsLoading(false);
    });
    return (React.createElement(Form, { className: 'compressed-form', form: form, layout: 'vertical', style: { width: '100%' }, onFinish: handleOnFinish, initialValues: getSnapshot(operationStore.operation) },
        React.createElement(UAVTypeSelectFormItem, { defaultValue: operationStore.operation.uavType, onChange: operationStore.operation.setUAVType }),
        React.createElement(UAVWeightSelectFormItem, { defaultValue: operationStore.operation.uavWeight, onChange: operationStore.operation.setUAVWeight }),
        React.createElement(LOSTypeSelectFormItem, { defaultValue: operationStore.operation.losType, onChange: operationStore.operation.setLOSType }),
        React.createElement(PilotControlSelectFormItem, { defaultValue: operationStore.operation.pilotControl, onChange: operationStore.operation.setPilotControl }),
        React.createElement(MaxAltitudeSliderFormItem, { defaultValue: operationStore.operation.maxAltitude, onChange: operationStore.operation.setMaxAltitude }),
        React.createElement(ResolutionSliderFormItem, null),
        React.createElement(ShapeRadioFormItem, { onClick: (e) => __awaiter(void 0, void 0, void 0, function* () { return yield mapStore.startSketch(e.target.defaultValue); }), label: 'Shape' }),
        React.createElement(Form.Item, { style: { margin: '15px 0 0 0' } },
            React.createElement(Button, { type: 'primary', htmlType: 'submit', loading: isLoading }, "Submit"))));
};
export default observer(RiskDemo);
