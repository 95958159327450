import { applySnapshot, types } from 'mobx-state-tree';
import { Operation } from 'models/Operation';
const OperationStore = types
    .model({
    operation: types.optional(Operation, {}),
})
    .actions(self => {
    // Snapshot is being passed in as an Object with any specific definition
    // It's assumed the snapshot would match the Operation model spec.
    const updateOperation = (snapshot) => {
        // applySnapshot will take the generic object and push its data
        // into specified model. In this case the Operation Model
        applySnapshot(self.operation, snapshot);
    };
    return { updateOperation };
});
export default OperationStore;
