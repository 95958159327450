var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { Form, Button, Modal, message, Select, Typography, Divider } from 'antd';
import { useAppContext } from 'stores/AppStore';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { getGPSSurface } from 'service/DataService';
import { ShapeRadioFormItem } from 'components/SharedComponents';
const { Option } = Select;
let result;
const GPSDemo = () => {
    // The useForm hook is an AntD hook
    const [form] = Form.useForm();
    // Import the operation and map stores into this component
    // These stores are loaded from the context created in AppStore
    const { operationStore, mapStore, accessToken } = useAppContext();
    const local = useLocalObservable(() => ({
        isLoading: false,
        sketch: mapStore.sketch,
        agl: 'dop_10m',
        toggleLoading() {
            local.isLoading = !local.isLoading;
        },
        setAGL(value) {
            local.agl = value;
            mapStore.setGPSAGL(value);
            mapStore.renderData(result, 'GPS', operationStore.operation.resolution);
        },
    }));
    // Every input from the form that has a 'name' property will be
    // passed back to this values object
    const handleOnFinish = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!mapStore.sketch) {
            Modal.info({
                title: 'Please add sketch',
                content: 'Processing may not proceed until you complete a sketch',
            });
            return;
        }
        // Construct a function payload object
        const payload = mapStore.sketch.features[0];
        // Request the surface
        local.toggleLoading();
        result = yield getGPSSurface(payload, accessToken).catch(ex => {
            message.error(ex.message);
        });
        mapStore.renderData(result, 'GPS', operationStore.operation.resolution);
        local.toggleLoading();
    });
    return (React.createElement(Form, { className: 'compressed-form', form: form, layout: 'vertical', style: { width: '100%' }, onFinish: handleOnFinish, initialValues: local },
        React.createElement("div", { style: { wordWrap: 'break-word', width: '260px' } },
            React.createElement(Typography.Text, null, "Static GPS data from December 12, 2021.")),
        React.createElement(Divider, null),
        React.createElement(Form.Item, { label: 'AGL', name: 'agl' },
            React.createElement(Select, { onChange: (value) => local.setAGL(value) },
                React.createElement(Option, { value: 'dop_1m' }, "1 m"),
                React.createElement(Option, { value: 'dop_10m' }, "10 m"),
                React.createElement(Option, { value: 'dop_40m' }, "40 m"))),
        React.createElement(ShapeRadioFormItem, { onClick: (e) => __awaiter(void 0, void 0, void 0, function* () { return yield mapStore.startSketch(e.target.defaultValue); }), label: 'Shape' }),
        React.createElement(Form.Item, { style: { margin: '15px 0 0 0' } },
            React.createElement(Button, { type: 'primary', htmlType: 'submit', loading: local.isLoading }, "Submit"))));
};
export default observer(GPSDemo);
