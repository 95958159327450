var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { config } from 'config';
import { urlCumulativeRiskSurface, urlPopulationDensitySurface, urlWeatherSurface, urlStrikeSurface, urlSuitabilitySurface, urlCompliancePointEmulator, urlHexesOnly, urlRoute, urlRouteV2, urlMetadata, urlSurfaceV2, urlGPSHexes, urlElevationProfile, urlDetectionDataProxy, urlSuitabilitySurfaceV2, } from 'utils/url';
const demoApiKey = config.demoApiKey;
const apiKey = config.apiKey;
export const getCumulativeSurface = (data, token) => __awaiter(void 0, void 0, void 0, function* () {
    return fetch(urlCumulativeRiskSurface, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': apiKey,
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then(response => response.json())
        .then(data => {
        if (data.statusCode !== 200) {
            throw new Error(data.message);
        }
        return data;
    });
});
export const getPopulationDensity = (data, token) => __awaiter(void 0, void 0, void 0, function* () {
    return fetch(urlPopulationDensitySurface, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': demoApiKey,
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then(response => {
        if (response.status !== 200) {
            throw new Error('Bad response from population density service');
        }
        return response.json();
    })
        .catch(error => {
        throw new Error(error);
    });
});
export const getWeatherSurface = (data, token) => __awaiter(void 0, void 0, void 0, function* () {
    return fetch(urlWeatherSurface, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': demoApiKey,
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then(response => {
        if (response.status !== 200) {
            throw new Error('Bad response from weather service');
        }
        return response.json();
    })
        .catch(error => {
        throw new Error(error);
    });
});
export const getStrikeSurface = (data, token) => __awaiter(void 0, void 0, void 0, function* () {
    return fetch(urlStrikeSurface, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            encoding: 'utf-8',
            'x-api-key': demoApiKey,
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then(response => {
        if (response.status !== 200) {
            throw new Error('Bad response from chance of strike service');
        }
        return response.json();
    })
        .catch(error => {
        throw new Error(error);
    });
});
export const getSurfaceV2 = (data, token) => __awaiter(void 0, void 0, void 0, function* () {
    return fetch(urlSurfaceV2, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': apiKey,
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then((response) => response.json())
        .then((data) => {
        if (data.statusCode !== 200) {
            throw new Error(data.message);
        }
        return data;
    });
});
export const getSuitabilitySurfaceV2 = (data, token) => __awaiter(void 0, void 0, void 0, function* () {
    return fetch(urlSuitabilitySurfaceV2, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': apiKey,
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then((response) => response.json())
        .then((data) => {
        if (data.statusCode !== 200) {
            throw new Error(data.message);
        }
        return data;
    });
});
export const getSuitabilitySurface = (data, token) => __awaiter(void 0, void 0, void 0, function* () {
    return fetch(urlSuitabilitySurface, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': apiKey,
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then((response) => response.json())
        .then((data) => {
        if (data.statusCode !== 200) {
            throw new Error(data.message);
        }
        return data;
    });
});
export const startCompliancePointEmulator = (data) => __awaiter(void 0, void 0, void 0, function* () {
    return fetch(urlCompliancePointEmulator, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    }).then(response => response.text());
});
export const getHexesOnly = (data) => __awaiter(void 0, void 0, void 0, function* () {
    return fetch(urlHexesOnly, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    }).then(response => response.json());
});
export const getRoute = (data, token) => __awaiter(void 0, void 0, void 0, function* () {
    return fetch(urlRoute, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': apiKey,
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    }).then(resp => resp.json());
});
export const getRouteV2 = (data, token) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    // User added coordinate Points from UI
    const pointCoords = data.waypoints.coordinates;
    // User added sketch geometry from UI
    const sketchCoords = (_a = data.geometry) === null || _a === void 0 ? void 0 : _a.coordinates;
    const payload = {
        resolution: data.resolution,
        layers: data.features,
        waypoints: {
            type: 'MultiPoint',
            coordinates: pointCoords,
        },
    };
    if (sketchCoords)
        payload.geometry = { type: 'Polygon', coordinates: sketchCoords };
    if (data.surface) {
        delete payload.layers;
        delete payload.geometry;
        payload.surface = data.surface;
    }
    return fetch(urlRouteV2, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': apiKey,
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
    }).then(resp => resp.json());
});
export const getMetadata = (token) => __awaiter(void 0, void 0, void 0, function* () {
    return fetch(urlMetadata, {
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': apiKey,
            Authorization: `Bearer ${token}`,
        },
    }).then(data => data.json());
});
export const getGPSSurface = (data, token) => __awaiter(void 0, void 0, void 0, function* () {
    return fetch(urlGPSHexes, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': demoApiKey,
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then(response => response.json())
        .then((data) => {
        if (data.status && data.status !== 200) {
            throw new Error(data.message);
        }
        return data;
    });
});
export const getElevationProfile = (data, token) => __awaiter(void 0, void 0, void 0, function* () {
    return fetch(urlElevationProfile, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': demoApiKey,
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then(response => response.json())
        .then((data) => {
        if (data.status && data.status !== 200) {
            throw new Error('Bad response from elevation data service');
        }
        return data;
    });
});
export const getGrids = (data) => __awaiter(void 0, void 0, void 0, function* () {
    return fetch('https://services6.arcgis.com/ssFJjBXIUyZDrSYZ/ArcGIS/rest/services/FAA_UAS_FacilityMap_Data_V5/FeatureServer/0/query', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: 'where=1%3D1&objectIds=&time=&geometry=' +
            encodeURIComponent(JSON.stringify(data)) +
            '&geometryType=esriGeometryPolyline&inSR=4326&spatialRel=esriSpatialRelIntersects&outFields=CEILING&returnGeometry=true&f=geojson',
    })
        .then(response => response.json())
        .then((data) => {
        if (data.status && data.status !== 200) {
            throw new Error('Bad response from elevation data service');
        }
        return data;
    });
});
export const getDetectionProxy = (data) => __awaiter(void 0, void 0, void 0, function* () {
    return fetch(urlDetectionDataProxy, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
        .then(response => response.json())
        .then((data) => {
        if (data.status && data.status !== 200) {
            throw new Error(data.message);
        }
        return data;
    });
});
