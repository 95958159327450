import { types } from 'mobx-state-tree';
import { createContext, useContext } from 'react';
import { RouteLog } from 'models/RouteLog';
import MapStore from 'stores/MapStore';
import OperationStore from 'stores/OperationStore';
import { Metadata } from 'models/Metadata';
const AppStore = types
    .model({
    accessToken: types.optional(types.string, ''),
    mapStore: types.optional(MapStore, {}),
    operationStore: types.optional(OperationStore, {}),
    routeLogs: types.optional(types.array(RouteLog), []),
    metadata: types.optional(types.array(Metadata), []),
    selectedDemoKey: types.optional(types.string, 'cmRisk'),
    elevationChartVisible: types.optional(types.boolean, false),
})
    .actions(self => {
    const setMetadata = (metadata) => (self.metadata = metadata);
    const saveAccessToken = (token) => (self.accessToken = token);
    const setRouteLogs = (logs) => (self.routeLogs = logs);
    const setSelectedDemoKey = (key) => (self.selectedDemoKey = key);
    const setElevationChartVisible = (visible) => (self.elevationChartVisible = visible);
    return {
        saveAccessToken,
        setRouteLogs,
        setSelectedDemoKey,
        setMetadata,
        setElevationChartVisible,
    };
})
    .views(self => ({
    get totalRouteDuration() {
        if (!self.routeLogs.length)
            return 0;
        return self.routeLogs.map(log => log.durationNano).reduce((prev, next) => prev + next);
    },
}));
// useContext is a React thing. By creating an AppStore context
// we can use React hooks to pull any store into any component, anywhere
const store = AppStore.create();
const context = createContext(store);
export const useAppContext = () => useContext(context);
