var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, message } from 'antd';
import Map from 'components/Map';
import InputContainer from 'components/InputContainer';
import { useAppContext } from 'stores/AppStore';
import { config } from 'config';
const ChartWrapper = styled.div `
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%;
  background-color: white;
  opacity: 0.9;
  z-index: 999;
`;
const LogoutWrapper = styled.div `
  position: absolute;
  top: 80px;
  left: 10px;
  z-index: 999;
  border-radius: 5px;
  -webkit-box-shadow: 0 5px 10px -1px rgba(0, 0, 0, 0.21);
  box-shadow: 0 5px 10px -1px rgba(0, 0, 0, 0.21);
`;
const App = () => {
    const { getAccessTokenSilently, getAccessTokenWithPopup, logout } = useAuth0();
    const store = useAppContext();
    useEffect(() => {
        const getAccessToken = () => __awaiter(void 0, void 0, void 0, function* () {
            let accessToken;
            const options = { audience: config.auth0Audience };
            // When running locally, you cannot get the access token silently
            try {
                accessToken = yield getAccessTokenSilently(options);
            }
            catch (e) {
                accessToken = yield getAccessTokenWithPopup(options);
            }
            if (accessToken) {
                store.saveAccessToken(accessToken);
            }
            else {
                message.error('Unable to retrieve access token.');
            }
        });
        getAccessToken();
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Map, null),
        React.createElement(LogoutWrapper, null,
            React.createElement(Button, { onClick: () => logout() }, "Logout")),
        React.createElement(InputContainer, null),
        React.createElement(ChartWrapper, { id: 'chart', style: { display: store.elevationChartVisible ? 'block' : 'none' } })));
};
export default observer(App);
