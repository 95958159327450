var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { Button, Modal, message, Form, Typography, Divider } from 'antd';
import { useAppContext } from 'stores/AppStore';
import { observer } from 'mobx-react-lite';
import { startCompliancePointEmulator, getHexesOnly } from 'service/DataService';
import { urlCompliancePointNodeSocket } from 'utils/url';
import { ShapeRadioFormItem } from 'components/SharedComponents';
import { io } from 'socket.io-client';
const ComplianceDemo = () => {
    // The useForm hook is an AntD hook
    const [form] = Form.useForm();
    // Import the operation and map stores into this component
    // These stores are loaded from the context created in AppStore
    const { operationStore, mapStore } = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    // Every input from the form that has a 'name' property will be
    // passed back to this event object
    const handleOnFinish = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!mapStore.sketch) {
            Modal.info({
                title: 'Please add sketch',
                content: 'Processing may not proceed until you complete a sketch',
            });
            return;
        }
        // Construct a function payload object
        const payload = {
            coordinates: mapStore.sketch.features[0].geometry.coordinates,
        };
        const realtimeLayer = mapStore.getRealtimeLayer();
        // Clear points from the realtime layer, if any exist
        for (const id in realtimeLayer._features) {
            realtimeLayer.remove(realtimeLayer.getFeature(id));
        }
        // Also clear data from other components
        const dataFromOtherComponents = mapStore.getRenderLayer();
        dataFromOtherComponents.clearLayers();
        const socket = io(urlCompliancePointNodeSocket);
        // Each point needs a unique ID, otherwise it just updates the same point each time
        // a new one comes in
        let idCounter = 0;
        socket.on('message', e => {
            // Add an ID onto each point
            if (typeof e != 'string') {
                e.id = idCounter;
            }
            // Need to wrap the json dict into an array - idk why!
            realtimeLayer.update([e]);
            idCounter += 1;
        });
        setIsLoading(true);
        // Get blank hexes for visualization
        const blankHexes = yield getHexesOnly(payload).catch(ex => {
            message.error(ex.message);
        });
        // Render blank hexes when they come in
        mapStore.renderData(blankHexes, 'BLANK-HEXES', operationStore.operation.resolution);
        // Kickoff the point emulation process
        const result = yield startCompliancePointEmulator(payload).catch(ex => {
            message.error(ex.message);
        });
        console.log(result);
        setIsLoading(false);
    });
    return (React.createElement(Form, { className: 'compressed-form', form: form, layout: 'vertical', style: { width: '100%' }, onFinish: handleOnFinish },
        React.createElement("div", { style: { wordWrap: 'break-word', width: '260px' } },
            React.createElement(Typography.Text, null, "Simulates drone tracks around the operational area and measures operational compliance in real time. This process is able to measure compliance for hundreds of tracks a second.")),
        React.createElement(Divider, null),
        React.createElement(ShapeRadioFormItem, { onClick: (e) => __awaiter(void 0, void 0, void 0, function* () { return yield mapStore.startSketch(e.target.defaultValue); }), label: 'Draw operational area' }),
        React.createElement(Form.Item, { style: { margin: '20px 0 0 0' } },
            React.createElement(Button, { type: 'primary', htmlType: 'submit', loading: isLoading }, "Simulate Drone Tracks"))));
};
export default observer(ComplianceDemo);
