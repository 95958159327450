var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { Form, Button, Modal, message, Input, Alert } from 'antd';
import { useAppContext } from 'stores/AppStore';
import { observer } from 'mobx-react-lite';
import { getSuitabilitySurfaceV2 } from 'service/DataService';
import { ResolutionSliderFormItem, ShapeRadioFormItem } from 'components/SharedComponents';
// Intialize data service result here so we can reuse it when re-rendering
const defaultRequest = `[\n{"code": "roads", "risk": 1}\n]`;
const SuitabilityDemoV2 = () => {
    // Import the operation and map stores into this component
    // These stores are loaded from the context created in AppStore
    const { operationStore, mapStore, accessToken } = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    const [layersPayload, setLayersPayload] = useState(defaultRequest);
    // Every input from the form that has a 'name' property will be
    // passed back to this values object
    const handleOnFinish = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!mapStore.sketch) {
            Modal.info({
                title: 'Please add sketch',
                content: 'Processing may not proceed until you complete a sketch',
            });
            return;
        }
        if (!layersPayload) {
            message.error('Layers payload is required');
            return;
        }
        let layers;
        try {
            layers = JSON.parse(layersPayload);
        }
        catch (e) {
            message.error(`Suitable features payload invalid: ${e.toString()}`);
            console.error(e);
            return;
        }
        if (!(layers instanceof Array)) {
            message.error('Layers payload must be an array');
            return;
        }
        // Construct a function payload object
        const payload = {
            geometry: mapStore.sketch.features[0].geometry,
            layers,
            resolution: operationStore.operation.resolution,
        };
        // Request the surface
        setIsLoading(true);
        getSuitabilitySurfaceV2(payload, accessToken)
            .then((result) => {
            if ((result === null || result === void 0 ? void 0 : result.statusCode) !== 200) {
                message.error((result === null || result === void 0 ? void 0 : result.message) || 'There was an error; check the console');
                console.error('Response:', result);
                return;
            }
            mapStore.clearEverything();
            mapStore.renderData(result.data, 'SUITABILITY-V2', operationStore.operation.resolution);
        })
            .catch(ex => {
            message.error(ex.message);
        })
            .finally(() => setIsLoading(false));
    });
    return (React.createElement(Form, { className: 'compressed-form', layout: 'vertical', style: { width: '100%' }, onFinish: handleOnFinish },
        React.createElement(Alert, { showIcon: true, message: 'In beta testing', description: 'This demo may be unstable', type: 'warning' }),
        React.createElement(ResolutionSliderFormItem, null),
        React.createElement(Form.Item, { label: 'Layers payload' },
            React.createElement(Input.TextArea, { defaultValue: defaultRequest, onChange: e => setLayersPayload(e.target.value) })),
        React.createElement(ShapeRadioFormItem, { onClick: (e) => __awaiter(void 0, void 0, void 0, function* () { return yield mapStore.startSketch(e.target.defaultValue); }), label: 'Shape' }),
        React.createElement(Form.Item, { style: { margin: '15px 0 0 0' } },
            React.createElement(Button, { type: 'primary', htmlType: 'submit', loading: isLoading }, "Submit"))));
};
export default observer(SuitabilityDemoV2);
