import { config } from 'config';
// Go function app
export const urlRoute = `${config.apiUrl}/v1/route`;
export const urlRouteV2 = `${config.apiUrl}/v2/route`;
export const urlMetadata = `${config.apiUrl}/v1/metadata`;
export const urlCumulativeRiskSurface = `${config.apiUrl}/v1/groundRisk`;
export const urlSuitabilitySurface = `${config.apiUrl}/v1/suitability`;
export const urlSuitabilitySurfaceV2 = `${config.apiUrl}/v2/classify`;
export const urlPopulationDensitySurface = `${config.demoApiUrl}/v1/demo-population-surface`;
export const urlWeatherSurface = `${config.demoApiUrl}/v1/demo-weather`;
export const urlStrikeSurface = `${config.demoApiUrl}/v1/demo-strike-probability`;
export const urlSurfaceV2 = `${config.apiUrl}/v2/surface`;
export const urlCompliancePointEmulator = 'https://func-data-poc-dev-cus.azurewebsites.net/api/opComplianceSimulatorPoints?code=ZQ2tb285uBNU1k17zVY7wKXwziySDgudleu5RdLZaRk2rBxa9AGsOA==';
export const urlCompliancePointNodeSocket = 'wss://serv-data-websocket-opcompliancedemo-dev-cus.azurewebsites.net:443';
export const urlHexesOnly = 'https://func-data-poc-dev-cus.azurewebsites.net/api/opComplianceSimulatorHexes?code=4u/K7SloUf/DYzsxFRmH1Bo08aah4LrWS/ekLUux3iaGA8KH0a9wAQ==';
export const urlGPSHexes = `${config.demoApiUrl}/v1/demo-gps`;
export const urlElevationProfile = `${config.demoApiUrl}/v1/elevation`;
export const urlGridCeiling = 'https://services6.arcgis.com/ssFJjBXIUyZDrSYZ/ArcGIS/rest/services/FAA_UAS_FacilityMap_Data_V5/FeatureServer/0/query';
export const urlDetectionDataProxy = 'https://func-data-poc-dev-cus.azurewebsites.net/api/demoDetectionComplianceProxy?code=k9JEKFDYDGnvAiEECBwWJrFtTQfJtalE1Vr0v1k_12sQAzFuEUjM7w==';
