import { types, getParent } from 'mobx-state-tree';
import * as L from 'leaflet';
import 'leaflet-draw';
import 'leaflet-realtime';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import 'leaflet-choropleth';
import buffer from '@turf/buffer';
import * as renderer from 'utils/render';
const MapStore = types
    .model('MapStore', {
    sketch: types.maybe(types.frozen()),
    showTooltips: types.optional(types.boolean, false),
    isHighlightPreferredChecked: types.optional(types.boolean, false),
    mapLock: types.optional(types.boolean, false),
    gpsAGL: types.optional(types.string, 'dop_10m'),
})
    .actions(() => ({
    runInAction(fn) {
        return fn();
    },
}))
    .actions(self => {
    let map, renderLayer, restrictedAirspaceLayer, detectionPointLayer, sketch, sketchHandler, realtimeLayer, markers, marker;
    /**
     * Creates a new leaflet map
     *
     * @param domElement Represents the id of the dom element the map is injected into
     */
    const createMap = (domElement) => {
        // Instantiate the map element and set the default location
        map = L.map(domElement, { preferCanvas: true }).setView([42.331052, -83.113378], 12);
        // Create a tile layer basemap and add to the map
        L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYXNsZGV2cyIsImEiOiJjbDF2OXlkMXEwd3l3M2lxdjMxMXExdmQ2In0.uC3BT868Qn6RhCHdDKAToQ', {
            maxZoom: 18,
            id: 'mapbox/streets-v11',
            tileSize: 512,
            zoomOffset: -1,
        }).addTo(map);
        const provider = new OpenStreetMapProvider();
        const searchControl = GeoSearchControl({
            provider: provider,
            showMarker: false,
            style: 'bar',
        });
        map.addControl(searchControl);
        sketch = new L.FeatureGroup().addTo(map);
        markers = new L.FeatureGroup().addTo(map);
        renderLayer = L.geoJSON().addTo(map);
        restrictedAirspaceLayer = L.geoJSON().addTo(map);
        detectionPointLayer = L.geoJSON(undefined, {
            pointToLayer: (feature, latlng) => {
                return L.circleMarker(latlng);
            },
        }).addTo(map);
        // @ts-ignore because Leaflet-realtime has no types
        realtimeLayer = L.realtime(undefined, {
            getFeatureId: function (f) {
                return f.id;
            },
            start: false,
            onEachFeature(feature, layer) {
                const compliance = feature.properties['is_compliant'];
                const popupContent = `<strong>Compliant? :</strong> ${compliance}<br>`;
                layer.bindPopup(popupContent);
            },
            pointToLayer: (feature, latlng) => {
                return L.circleMarker(latlng, renderer.styleCompliancePoint(feature));
            },
        }).addTo(map);
        map.on('click', handleAddMarker);
        // If the map zooms into a certain point, turn tooltips on, otherwise keep them off
        map.on('zoomend', handleTooltipToggle);
    };
    // map.onClick
    const handleAddMarker = (e) => {
        const parent = getParent(self, 1).selectedDemoKey;
        if (self.mapLock || (parent !== 'route' && parent !== 'routeV2'))
            return;
        const layers = markers.getLayers();
        if (layers.length < 100)
            markers.addLayer(L.marker(e.latlng));
    };
    const getMultiPoint = () => {
        const geoJson = markers.toGeoJSON();
        const coordinates = [];
        geoJson.features.map((feature) => {
            const point = feature.geometry;
            coordinates.push(point.coordinates);
        });
        return {
            type: 'MultiPoint',
            coordinates,
        };
    };
    const setMapLock = (val) => {
        self.mapLock = val;
    };
    const toggleMapLock = () => {
        self.mapLock = !self.mapLock;
    };
    const clearEverything = () => {
        self.mapLock = false;
        sketch.clearLayers();
        markers.clearLayers();
        renderLayer.clearLayers();
        restrictedAirspaceLayer.clearLayers();
        detectionPointLayer.clearLayers();
    };
    /**
     * Starts a new sketch on the map. Returning a promise for when the sketch is completed
     *
     * @param sketchType Type of sketch shape to sketch
     */
    const startSketch = (sketchType) => {
        // If there is already a sketch in progress, disable
        // the sketch and clear any event listeners
        if (sketchHandler) {
            sketchHandler.disable();
            map.removeEventListener(L.Draw.Event.CREATED);
        }
        // Create new promise
        return new Promise(resolve => {
            map.on(L.Draw.Event.CREATED, (e) => {
                sketch.clearLayers();
                sketch.addLayer(e.layer);
                let data = sketch.toGeoJSON();
                // If the sketch is a circle, buffer it to turn it into a polygon
                if (e.layerType === 'circle') {
                    data = buffer(data, e.layer._mRadius, { units: 'meters' });
                }
                map.removeEventListener(L.Draw.Event.CREATED);
                self.runInAction(() => {
                    self.sketch = data;
                });
                resolve(data);
            });
            switch (sketchType) {
                case 'circle':
                    sketchHandler = new L.Draw.Circle(map);
                    break;
                case 'rectangle':
                    sketchHandler = new L.Draw.Rectangle(map);
                    break;
                case 'polygon':
                    sketchHandler = new L.Draw.Polygon(map);
                    break;
                case 'line':
                    sketchHandler = new L.Draw.Polyline(map);
                    break;
            }
            sketchHandler.enable();
        });
    };
    /**
     * Renders the input hex data on the map
     *
     * @param data The data to render (assumed GEOJSON format?)
     */
    const renderData = (data, dataType, res) => {
        // Generic rendering function for all data types
        switch (dataType) {
            case 'RISK':
                renderer.renderCumulativeSurface(data, renderLayer);
                break;
            case 'FILE_SURFACE':
                renderer.renderFileSurface(data, renderLayer);
                break;
            case 'SURFACE':
                renderer.renderSurface(data, renderLayer);
                break;
            case 'SUITABILITY-V2':
                renderer.renderSuitabilityV2(data, renderLayer);
                break;
            case 'SUITABILITY':
                renderer.renderSuitabilitySurface(data, renderLayer, self.isHighlightPreferredChecked);
                break;
            case 'ROUTEV2':
                renderer.renderRouteV2(data, renderLayer);
                break;
            case 'ROUTE':
                renderer.renderRoute(data, renderLayer);
                break;
            case 'DENSITY':
                // For this case, 'data' will be a chorpleth layer instead of GeoJSON
                renderer.renderDensitySurface(data, renderLayer);
                break;
            case 'STRIKE':
                renderer.renderStrikeSurface(data, renderLayer);
                break;
            case 'WEATHER-WIND':
                renderer.renderWeatherSurface(data, renderLayer, 'windSpeed', self.showTooltips, res);
                break;
            case 'WEATHER-TEMP':
                renderer.renderWeatherSurface(data, renderLayer, 'temperature', self.showTooltips, res);
                break;
            case 'WEATHER-PRECIP':
                renderer.renderWeatherSurface(data, renderLayer, 'precipChance', self.showTooltips, res);
                break;
            case 'BLANK-HEXES':
                renderer.renderBlankHexes(data, renderLayer);
                break;
            case 'GPS':
                renderer.renderGPSHexes(data, renderLayer, self.gpsAGL);
                break;
            case 'ELEVATION-PROFILE':
                // show tooltips=true is very important, otherwise labels disappear on zoom in/out
                // has to do with function wrote by Jacob for weather
                renderer.renderFAAGrids(data, renderLayer, (self.showTooltips = true));
                break;
            case 'DETECTION-COMPLIANCE-GRIDS':
                renderer.renderDetectionComplianceFAAGrids(data, renderLayer);
                break;
            case 'DETECTION-COMPLIANCE-RESTRICTED':
                renderer.renderDetectionComplianceRestricted(data, restrictedAirspaceLayer);
                break;
            case 'DETECTION-COMPLIANCE-POINTS':
                renderer.renderDetectionCompliancePoints(data, detectionPointLayer);
                break;
            default:
                throw new Error('invalid dataType: ' + dataType);
        }
    };
    const addPointMarker = (lat, lon) => {
        marker = L.marker([lat, lon]).addTo(map);
    };
    const clearPointMarker = () => {
        if (map.hasLayer(marker))
            map.removeLayer(marker);
    };
    const getRenderLayer = () => renderLayer;
    const getRealtimeLayer = () => realtimeLayer;
    const makePopChoroplethLayer = (data, numSteps) => {
        return L.choropleth(data, {
            valueProperty: 'popClass',
            scale: ['green', 'yellow', 'red'],
            steps: numSteps,
            mode: 'e',
            style: {
                color: '#3e3f40',
                weight: 0.1,
                fillOpacity: 0.4,
            },
            onEachFeature: function (feature, layer) {
                const { popClass, popPerSqMi } = feature.properties;
                const popupContent = `<strong>Population Class:</strong> ${popClass}<br/><strong>Pop Sq Mi:</strong> ${Math.round(popPerSqMi)}`;
                layer.bindPopup(popupContent);
            },
        });
    };
    const cleanup = () => {
        // Turn off all map events
        map.off();
        // Remove the map from the DOM
        map.remove();
    };
    const handleTooltipToggle = () => {
        const zoomThreshold = 12;
        const zoom = map.getZoom();
        if (!self.showTooltips) {
            map.eachLayer(l => {
                if (l.getTooltip) {
                    const toolTip = l.getTooltip();
                    if (toolTip) {
                        map.closeTooltip(toolTip);
                    }
                }
            });
        }
        if (self.showTooltips) {
            if (zoom >= zoomThreshold) {
                map.eachLayer(l => {
                    if (l.getTooltip) {
                        const toolTip = l.getTooltip();
                        if (toolTip) {
                            map.addLayer(toolTip);
                        }
                    }
                });
            }
            else if (zoom < zoomThreshold) {
                map.eachLayer(l => {
                    if (l.getTooltip) {
                        const toolTip = l.getTooltip();
                        if (toolTip) {
                            map.closeTooltip(toolTip);
                        }
                    }
                });
            }
        }
    };
    const setShowTooltips = (e) => {
        self.showTooltips = e;
        handleTooltipToggle();
    };
    const setHighlightPreferred = (e) => {
        self.isHighlightPreferredChecked = e;
    };
    const setGPSAGL = (gpsAGL) => {
        self.gpsAGL = gpsAGL;
    };
    const setMapView = (latlon, zoom) => {
        map.setView(latlon, zoom);
    };
    return {
        setMapLock,
        toggleMapLock,
        createMap,
        cleanup,
        startSketch,
        renderData,
        makePopChoroplethLayer,
        setShowTooltips,
        setHighlightPreferred,
        getRenderLayer,
        getRealtimeLayer,
        clearEverything,
        getMultiPoint,
        setGPSAGL,
        addPointMarker,
        clearPointMarker,
        setMapView,
    };
});
export default MapStore;
