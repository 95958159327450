// @ts-nocheck
import * as d3 from 'd3';
export const drawChart = (input, altitude, addPointMarker, clearPointMarker) => {
    let ymin;
    let ymax = 0;
    // renaming columns, converting from meters to ft / mi, rounding
    const mappedFeatures = input.map(({ m, In_Y, In_X, terrainNAVD88, Ceiling }) => ({
        Distance: parseFloat(m * 0.00062137119223733),
        Latitude: parseFloat(In_Y),
        Longitude: parseFloat(In_X),
        Elevation: parseFloat(terrainNAVD88),
        Grid: parseFloat(Ceiling),
        Ceiling: parseFloat(Ceiling) + parseFloat(terrainNAVD88), //feet
    }));
    // add new Elevation column based on input altitude
    const mappedFeaturesWithElevation = mappedFeatures.map(object => {
        return Object.assign(Object.assign({}, object), { Operation: parseFloat(object.Elevation + altitude) });
    });
    // get Operation altitude from the origin
    const constant_altitude = mappedFeaturesWithElevation[0]['Operation'];
    // final profile data, contains the constant Operation altitude from point of origin
    const profile = mappedFeaturesWithElevation.map(object => {
        return Object.assign(Object.assign({}, object), { Altitude: parseFloat(constant_altitude) });
    });
    // get maximum elevation for that particular route
    const max = d3.max(profile, d => d.Elevation);
    // scale used to make the chart look good for different terrain altitudes (high, low)
    const scaleMax = max < 2000 ? 5 : max < 6000 ? 10 : max < 12000 ? 28 : 35;
    const data = {
        distance: profile.map(d => parseFloat(d['Distance'])),
        series: ['Altitude', 'Elevation', 'Operation', 'Ceiling'].map(test_cell => ({
            name: test_cell,
            values: profile.map(d => d[test_cell]),
        })),
        lat: profile.map(d => parseFloat(d['Latitude'])),
        lon: profile.map(d => parseFloat(d['Longitude'])),
    };
    // to refresh the chart
    d3.selectAll('#chart > *').remove();
    clearPointMarker();
    const margin = { top: 30, right: 30, bottom: 40, left: 50 };
    const height = document.getElementById('chart').clientHeight;
    const width = document.getElementById('chart').clientWidth;
    // had to do the -1 because weird scroll bars were appearing
    const svg = d3
        .select('#chart')
        .append('svg')
        .attr('height', height - 1)
        .attr('width', width - 1)
        .style('overflow', 'visible')
        .style('border', '1px solid black')
        .append('g');
    // draw X Axis
    const x = d3
        .scaleLinear()
        .domain([0, d3.max(data.distance)])
        .range([margin.left + 1, width - margin.right]);
    svg
        .append('g')
        .attr('transform', `translate(0,${height - margin.bottom})`)
        .call(d3.axisBottom(x).tickSizeOuter(0))
        // Add label
        .append('text')
        .attr('fill', '#000')
        .attr('font-size', '12px')
        .text('Distance (mi)')
        .attr('x', margin.left + (width - margin.left - margin.right) / 2)
        .attr('y', 30); // Relative to the x axis.
    // handling negative Terrain Elevation values
    if (d3.min(profile, d => d.Elevation) > 0) {
        ymin =
            Math.round((d3.min(profile, d => d.Elevation) - d3.min(profile, d => d.Elevation) / scaleMax) / 100) * 100;
    }
    else {
        ymin = 0 - Math.abs(d3.min(profile, d => d.Elevation)) * 1.2;
    }
    ymax =
        parseFloat(Math.max(d3.max(profile, d => d.Ceiling), constant_altitude)) +
            parseFloat(Math.abs(d3.min(profile, d => d.Operation))) / scaleMax;
    const y = d3
        .scaleLinear()
        .domain([ymin, ymax])
        .range([height - margin.bottom - 1, margin.top]);
    svg
        .append('g')
        .attr('transform', `translate(${margin.left},0)`)
        .call(d3
        .axisLeft(y)
        .ticks(height / 50)
        .tickSizeOuter(0))
        .call(g => g
        .selectAll('.tick line')
        .clone()
        .attr('x2', width - margin.left - margin.right)
        .attr('stroke-opacity', 0.2))
        // Add label
        .append('text')
        .attr('fill', '#000')
        .attr('stroke-opacity', 1)
        .attr('font-size', '12px')
        .text('↑ Elevation (ft)')
        .attr('transform', 'rotate(0)')
        .attr('x', margin.top + 5)
        .attr('y', margin.top - 10); // Relative to the y axis.
    // Add the FAA red area
    svg
        .append('path')
        .datum(profile)
        .attr('fill', '#EB5757')
        .attr('fill-opacity', 0.3)
        .attr('d', d3
        .area()
        .x(d => x(d.Distance))
        .curve(d3.curveMonotoneX)
        .y0(d => y(d.Ceiling))
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .y1(d => y(ymax)));
    // Add the FAA green area
    svg
        .append('path')
        .datum(profile)
        .attr('fill', '#306900')
        .attr('fill-opacity', 0)
        .attr('d', d3
        .area()
        .x(d => x(d.Distance))
        .curve(d3.curveMonotoneX)
        .y0(d => y(d.Ceiling))
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .y1(d => y(ymin)));
    // Add the terrain area
    svg
        .append('path')
        .datum(profile)
        .attr('stroke', '#F2994A')
        .attr('stroke-width', 0)
        .attr('fill', '#F2994A')
        .attr('fill-opacity', 0.3)
        .attr('d', d3
        .area()
        .x(d => x(d.Distance))
        .curve(d3.curveMonotoneX)
        .y0(d => y(d.Elevation))
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .y1(d => y(ymin)));
    const line = d3
        .line()
        .curve(d3.curveMonotoneX)
        .defined(d => !isNaN(d))
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .x((d, i) => x(data.distance[i]))
        .y(d => y(d));
    // Add the line
    svg
        .append('g')
        .attr('fill', 'none')
        //.attr('stroke', 'steelblue')
        .attr('stroke-width', 2)
        .attr('stroke-linejoin', 'round')
        .attr('stroke-linecap', 'round')
        .selectAll('path')
        .data(data.series)
        .join('path')
        .style('mix-blend-mode', 'multiply')
        .style('stroke-dasharray', function (d) {
        if (d.name == 'Altitude') {
            return '4, 4';
        }
    })
        .style('stroke', function (d) {
        if (d.name == 'Altitude') {
            return '#b0b0b0';
        }
        else if (d.name == 'Operation') {
            return '#2F80ED';
        }
        else if (d.name == 'Ceiling') {
            return '#9e110e';
        }
        else {
            return '#050505';
        }
    })
        .style('stroke-width', function (d) {
        if (d.name == 'Altitude') {
            return 2;
        }
        else if (d.name == 'Elevation') {
            return 0;
        }
        else if (d.name == 'Ceiling') {
            return 0.5;
        }
        else {
            return 2;
        }
    })
        .attr('d', d => line(d.values));
    const focus = svg.append('g').attr('class', 'focus').style('display', 'none');
    focus
        .append('line')
        .attr('fill', 'none')
        .attr('stroke', '#4a4a4a')
        .attr('y1', margin.top)
        .attr('y2', height - 40);
    focus
        .append('rect')
        .attr('class', 'backgroundRect')
        .attr('fill', 'white')
        .attr('fill-opacity', '0.9')
        //.attr('stroke', 'none')
        .attr('y', height / 4)
        .attr('x', 7)
        .attr('stroke', '#b8b8b6')
        .attr('height', 85)
        .attr('width', 255)
        .attr('rx', 0)
        .attr('ry', 0);
    focus
        .append('text')
        .attr('class', 'operationtext')
        .attr('fill', '#535353')
        .attr('font-family', 'arial')
        .attr('font-size', '13px')
        .attr('x', 35)
        .attr('y', height / 4 + 52)
        .attr('dy', '.35em');
    focus
        .append('text')
        .attr('class', 'operationsymbol')
        .attr('fill', '#2F80ED')
        .attr('font-family', 'arial')
        .attr('font-size', '50px')
        .attr('x', 13)
        .attr('y', height / 4 + 52)
        .attr('dy', '.35em');
    focus
        .append('text')
        .attr('class', 'operationtextbold')
        .attr('fill', '#535353')
        .attr('font-family', 'arial')
        .attr('font-size', '13px')
        .attr('font-weight', 'bold')
        .style('text-anchor', 'end')
        .attr('x', 255)
        .attr('y', height / 4 + 52)
        .attr('dy', '.35em');
    focus
        .append('text')
        .attr('class', 'elevationtext')
        .attr('fill', '#535353')
        .attr('font-family', 'arial')
        .attr('font-size', '13px')
        .attr('x', 35)
        .attr('y', height / 4 + 72)
        .attr('dy', '.35em');
    focus
        .append('text')
        .attr('class', 'elevationsymbol')
        .attr('fill', '#F2994A')
        .attr('fill-opacity', '0.3')
        .attr('font-family', 'arial')
        .attr('font-size', '50px')
        .attr('x', 13)
        .attr('y', height / 4 + 72)
        .attr('dy', '.35em');
    focus
        .append('text')
        .attr('class', 'elevationtextbold')
        .attr('fill', '#535353')
        .attr('font-family', 'arial')
        .attr('font-size', '13px')
        .attr('font-weight', 'bold')
        .style('text-anchor', 'end')
        .attr('x', 255)
        .attr('y', height / 4 + 72)
        .attr('dy', '.35em');
    focus
        .append('text')
        .attr('class', 'constanttext')
        .attr('fill', '#535353')
        .attr('font-family', 'arial')
        .attr('font-size', '13px')
        .attr('x', 35)
        .attr('y', height / 4 + 32)
        .attr('dy', '.35em');
    focus
        .append('text')
        .attr('class', 'constantsymbol')
        .attr('fill', '#b0b0b0')
        .attr('font-family', 'arial')
        .attr('font-size', '50px')
        .attr('x', 13)
        .attr('y', height / 4 + 32)
        .attr('dy', '.35em');
    focus
        .append('text')
        .attr('class', 'constanttextbold')
        .attr('fill', '#535353')
        .attr('font-family', 'arial')
        .attr('font-size', '13px')
        .attr('font-weight', 'bold')
        .style('text-anchor', 'end')
        .attr('x', 255)
        .attr('y', height / 4 + 32)
        .attr('dy', '.35em');
    focus
        .append('text')
        .attr('class', 'ceilingtext')
        .attr('fill', '#535353')
        .attr('font-family', 'arial')
        .attr('font-size', '13px')
        .attr('x', 35)
        .attr('y', height / 4 + 12)
        .attr('dy', '.35em');
    focus
        .append('text')
        .attr('class', 'ceilingsymbol')
        .attr('fill', '#EB5757')
        .attr('fill-opacity', '0.3')
        .attr('font-family', 'arial')
        .attr('font-size', '50px')
        .attr('x', 13)
        .attr('y', height / 4 + 12)
        .attr('dy', '.35em');
    focus
        .append('text')
        .attr('class', 'ceilingtextbold')
        .attr('fill', '#535353')
        .attr('font-family', 'arial')
        .attr('font-size', '13px')
        .attr('font-weight', 'bold')
        .style('text-anchor', 'end')
        .attr('x', 255)
        .attr('y', height / 4 + 12)
        .attr('dy', '.35em');
    focus
        .append('circle')
        .attr('class', 'elevationcircle')
        .attr('fill', '#3d3d3d')
        .attr('cx', 0)
        .attr('r', 2);
    focus
        .append('circle')
        .attr('class', 'operationcircle')
        .attr('fill', '#4a4a4a')
        .attr('cx', 0)
        .attr('r', 2);
    focus
        .append('circle')
        .attr('class', 'constantcircle')
        .attr('fill', '#4a4a4a')
        .attr('cx', 0)
        .attr('r', 2);
    focus
        .append('circle')
        .attr('class', 'ceilingcircle')
        .attr('fill', '#4a4a4a')
        .attr('cx', 0)
        .attr('r', 2);
    // when hovering over the grapgh, a transversal line appears with a legend
    function mousemove() {
        clearPointMarker();
        const mouse = d3.pointer(event);
        const xm = x.invert(mouse[0]);
        //const ym = y.invert(mouse[1]);
        const i1 = d3.bisectLeft(data.distance, xm, 1);
        const i0 = i1 - 1;
        const i = xm - data.distance[i0] > data.distance[i1] - xm ? i1 : i0;
        // add map marker corresponding to point on graph
        addPointMarker(data.lat[i], data.lon[i]);
        const x0 = x.invert(mouse[0]);
        const dp1 = dataPointForMouseX(profile, x0);
        if (x(dp1.Distance)) {
            focus.attr('transform', 'translate(' + x(dp1.Distance) + ',0)');
            focus.select('.elevationsymbol').text('•');
            focus.select('.elevationtext').text('Terrain Elevation: ');
            focus.select('.elevationtextbold').text(dp1.Elevation + ' ft');
            focus.select('.operationsymbol').text('•');
            focus.select('.operationtext').text('Flight Level: ');
            focus.select('.operationtextbold').text(dp1.Operation + ' ft');
            focus.select('.constantsymbol').text('•');
            focus.select('.constanttext').text('Constant Flight Altitude: ');
            focus.select('.constanttextbold').text(dp1.Altitude + ' ft');
            focus.select('.ceilingsymbol').text('•');
            focus.select('.ceilingtext').text('FAA Grid Ceiling: ');
            focus.select('.ceilingtextbold').text(dp1.Ceiling + ' ft (' + dp1.Grid + "' AGL)");
            focus.select('.elevationcircle').attr('cy', y(dp1.Elevation));
            focus.select('.operationcircle').attr('cy', y(dp1.Operation));
            focus.select('.constantcircle').attr('cy', y(dp1.Altitude));
            focus.select('.ceilingcircle').attr('cy', y(dp1.Ceiling));
        }
    }
    svg
        .append('rect')
        .attr('class', 'overlay')
        .attr('fill', 'none')
        .attr('pointer-events', 'all')
        .attr('width', width)
        .attr('height', height)
        .on('mouseover', function () {
        focus.style('display', null);
    })
        .on('mouseout', function () {
        focus.style('display', 'none');
    })
        .on('mousemove', mousemove);
    const bisectDate = d3.bisector(function (d) {
        return d.Distance;
    }).left;
    function dataPointForMouseX(series, x0) {
        try {
            const i = bisectDate(series, x0, 1), d0 = series[i - 1], d1 = series[i], d = x0 - d0.Distance > d1.Distance - x0 ? d1 : d0;
            return d;
        }
        catch (error) {
            console.error(error);
            return 0;
        }
    }
    // clear markers when not hovering over graph
    const div = document.getElementById('chart');
    div.addEventListener('mouseleave', () => clearPointMarker(), false);
};
