// We have Auth0 set to have the same clientID for stage and prod
const auth0ProdClientID = 'YFSFTpfEd92eExkQ7RS1LUmldM6GIMpQ';
const auth0ProdDomain = 'id.airspacelink.com';
const env = process.env.CONFIG_ENV;
const globalConfig = {
    local: {
        // Auth0
        auth0Domain: 'airspacelink-dev.us.auth0.com',
        auth0Audience: 'https://apim-eng-dev-cus.azure-api.net',
        auth0ClientID: 'IyvnBrk1OIVW8swaNzZ8sR3JtWVMkFQD',
        // API
        demoApiUrl: `https://localhost:7071/demo`,
        demoApiKey: process.env.DEMO_API_KEY,
        apiUrl: 'http://localhost:7071/api',
        apiKey: process.env.AHD_API_KEY,
    },
    dev: {
        // Auth0
        auth0Domain: 'airspacelink-dev.us.auth0.com',
        auth0Audience: 'https://apim-eng-dev-cus.azure-api.net',
        auth0ClientID: 'IyvnBrk1OIVW8swaNzZ8sR3JtWVMkFQD',
        // API
        demoApiUrl: `https://airhub-api-dev.airspacelink.com/demo` + (process.env.PR_BUILD || ''),
        demoApiKey: process.env.DEMO_API_KEY,
        apiUrl: `https://airhub-api-dev.airspacelink.com` + (process.env.PR_BUILD || ''),
        apiKey: process.env.AHD_API_KEY,
    },
    stage: {
        // Auth0 (uses same tenant as prod)
        auth0Domain: auth0ProdDomain,
        auth0Audience: 'https://apim-eng-stage-cus.azure-api.net',
        auth0ClientID: auth0ProdClientID,
        // API
        demoApiUrl: 'https://airhub-api-sandbox.airspacelink.com/demo',
        demoApiKey: process.env.DEMO_API_KEY,
        apiUrl: 'https://airhub-api-sandbox.airspacelink.com',
        apiKey: process.env.AHD_API_KEY,
    },
    stage_beta: {
        // Auth0 (uses same tenant as prod)
        auth0Domain: auth0ProdDomain,
        auth0Audience: 'https://apim-eng-stage-cus.azure-api.net',
        auth0ClientID: auth0ProdClientID,
        // API
        demoApiUrl: 'https://airhub-api-sandbox.airspacelink.com/demo',
        demoApiKey: process.env.DEMO_API_KEY,
        apiUrl: 'https://airhub-api-sandbox.airspacelink.com/beta',
        apiKey: process.env.AHD_API_KEY,
    },
    prod: {
        // Auth0 (uses same tenant as stage)
        auth0Domain: auth0ProdDomain,
        auth0Audience: 'https://apim-eng-prod-cus.azure-api.net',
        auth0ClientID: auth0ProdClientID,
        // API
        demoApiUrl: 'https://airhub-api.airspacelink.com/demo',
        demoApiKey: process.env.DEMO_API_KEY,
        apiUrl: 'https://airhub-api.airspacelink.com',
        apiKey: process.env.AHD_API_KEY,
    },
    prod_beta: {
        // Auth0 (uses same tenant as stage)
        auth0Domain: auth0ProdDomain,
        auth0Audience: 'https://apim-eng-prod-cus.azure-api.net',
        auth0ClientID: auth0ProdClientID,
        // API
        demoApiUrl: 'https://airhub-api.airspacelink.com/demo',
        demoApiKey: process.env.DEMO_API_KEY,
        apiUrl: 'https://airhub-api.airspacelink.com/beta',
        apiKey: process.env.AHD_API_KEY,
    },
};
export const config = globalConfig[env];
export { env };
