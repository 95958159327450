var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { Form, Button, Modal, Switch, message, Input } from 'antd';
import { useAppContext } from 'stores/AppStore';
import { getSnapshot } from 'mobx-state-tree';
import { observer } from 'mobx-react-lite';
import { UAVTypeSelectFormItem, UAVWeightSelectFormItem, LOSTypeSelectFormItem, PilotControlSelectFormItem, MaxAltitudeSliderFormItem, ResolutionSliderFormItem, } from 'components/SharedComponents';
import { getMetadata, getRoute } from 'service/DataService';
const RouteDemo = () => {
    const [form] = Form.useForm();
    const store = useAppContext();
    const [routeLoading, setRouteLoading] = useState(false);
    const [metadataLoading, setMetadataLoading] = useState(false);
    const [hasRoute, setHasRoute] = useState(false);
    const [logsAreVisible, setLogsAreVisible] = useState(false);
    const [metadataVisible, setMetadataVisible] = useState(false);
    const [returnSurface, setReturnSurface] = useState(true);
    const [returnNetwork, setReturnNetwork] = useState(true);
    const [returnCorridor, setReturnCorridor] = useState(true);
    const [returnLogs, setReturnLogs] = useState(true);
    const [featuresPayload, setFeaturesPayload] = useState('');
    const handleOnFinish = () => __awaiter(void 0, void 0, void 0, function* () {
        const mp = store.mapStore.getMultiPoint();
        if (mp.coordinates.length < 2) {
            message.error('Please add at least two points');
            return;
        }
        let features = {};
        if (featuresPayload) {
            try {
                features = JSON.parse(featuresPayload);
            }
            catch (e) {
                message.error(`Suitable features payload invalid: ${e.toString()}`);
                console.error(e);
                return;
            }
        }
        const payload = Object.assign({
            geometry: mp,
            returnCorridor,
            returnSurface,
            returnLogs,
            returnNetwork,
        }, Object.assign({}, getSnapshot(store.operationStore.operation)), { features });
        setRouteLoading(true);
        try {
            const result = yield getRoute(payload, store.accessToken);
            if (result.statusCode >= 400) {
                message.error(result.message);
                return;
            }
            store.mapStore.renderData(result === null || result === void 0 ? void 0 : result.data, 'ROUTE', store.operationStore.operation.resolution);
            store.setRouteLogs(result === null || result === void 0 ? void 0 : result.data.logs);
            store.mapStore.setMapLock(true);
        }
        catch (e) {
            message.error(e.toString());
            console.error(e);
        }
        finally {
            setRouteLoading(false);
            setHasRoute(true);
        }
    });
    const fetchMetadata = () => __awaiter(void 0, void 0, void 0, function* () {
        setMetadataLoading(true);
        try {
            const result = yield getMetadata(store.accessToken);
            if (result.statusCode >= 400) {
                message.error(result.message);
                return;
            }
            store.setMetadata(result.data);
            setMetadataVisible(true);
        }
        catch (e) {
            message.error(e.toString());
            console.error(e);
        }
        finally {
            setMetadataLoading(false);
        }
    });
    const toggler = (fn) => (checked) => fn(checked);
    return (React.createElement(React.Fragment, null,
        React.createElement(Form, { className: 'compressed-form', form: form, layout: 'vertical', style: { width: '100%' }, onFinish: handleOnFinish, initialValues: getSnapshot(store.operationStore.operation) },
            React.createElement(UAVTypeSelectFormItem, { defaultValue: store.operationStore.operation.uavType, onChange: store.operationStore.operation.setUAVType }),
            React.createElement(UAVWeightSelectFormItem, { defaultValue: store.operationStore.operation.uavWeight, onChange: store.operationStore.operation.setUAVWeight }),
            React.createElement(LOSTypeSelectFormItem, { defaultValue: store.operationStore.operation.losType, onChange: store.operationStore.operation.setLOSType }),
            React.createElement(PilotControlSelectFormItem, { defaultValue: store.operationStore.operation.pilotControl, onChange: store.operationStore.operation.setPilotControl }),
            React.createElement(MaxAltitudeSliderFormItem, { defaultValue: store.operationStore.operation.maxAltitude, onChange: store.operationStore.operation.setMaxAltitude }),
            React.createElement(ResolutionSliderFormItem, null),
            React.createElement(Form.Item, { label: 'Suitable features payload' },
                React.createElement(Input.TextArea, { onChange: e => setFeaturesPayload(e.target.value) })),
            React.createElement(Form.Item, { label: 'Request Suitability Surface' },
                React.createElement(Switch, { defaultChecked: returnSurface, onChange: toggler(setReturnSurface) })),
            React.createElement(Form.Item, { label: 'Request network' },
                React.createElement(Switch, { defaultChecked: returnNetwork, onChange: toggler(setReturnNetwork) })),
            React.createElement(Form.Item, { label: 'Request corridor' },
                React.createElement(Switch, { defaultChecked: returnCorridor, onChange: toggler(setReturnCorridor) })),
            React.createElement(Form.Item, { label: 'Request performance logs' },
                React.createElement(Switch, { defaultChecked: returnLogs, onChange: toggler(setReturnLogs) })),
            React.createElement(Form.Item, { style: { margin: '15px 0 0 0' } },
                React.createElement(Button, { type: 'primary', htmlType: 'submit', loading: routeLoading }, "Submit"),
                React.createElement(Button, { type: 'primary', style: { marginLeft: '8px' }, onClick: store.mapStore.clearEverything }, "Clear")),
            React.createElement(Form.Item, { style: { margin: '15px 0 0 0' } },
                React.createElement(Button, { disabled: !hasRoute, type: 'primary', onClick: () => setLogsAreVisible(true) }, "Logs")),
            React.createElement(Form.Item, { style: { margin: '15px 0 0 0' } },
                React.createElement(Button, { type: 'primary', loading: metadataLoading, onClick: fetchMetadata }, "Get metadata sources"))),
        React.createElement(Modal, { width: 750, title: 'Logs', visible: logsAreVisible, onOk: () => setLogsAreVisible(false), onCancel: () => setLogsAreVisible(false) },
            React.createElement("table", { style: { width: '100%' } },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", null, "Step"),
                        React.createElement("th", { style: { textAlign: 'left' } }, "Duration (ms)"))),
                React.createElement("tbody", null, store.routeLogs.map((log, i) => (React.createElement("tr", { key: i },
                    React.createElement("td", null, log.step),
                    React.createElement("td", null, log.durationNano / 1000000))))),
                React.createElement("tfoot", { style: { borderTopWidth: '1px', borderColor: '#eee' } },
                    React.createElement("tr", { style: { padding: '4px' } },
                        React.createElement("td", null,
                            React.createElement("strong", null, "Total Duration")),
                        React.createElement("td", null,
                            React.createElement("strong", null, store.totalRouteDuration / 1000000)))))),
        React.createElement(Modal, { width: 750, title: 'Available metadata', visible: metadataVisible, onOk: () => setMetadataVisible(false), onCancel: () => setMetadataVisible(false) },
            React.createElement("table", null,
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", null, "Name"),
                        React.createElement("th", null, "Code"))),
                React.createElement("tbody", null, store.metadata.map((row) => (React.createElement("tr", { id: row.code, key: row.code },
                    React.createElement("td", null,
                        React.createElement("strong", null, row.name)),
                    React.createElement("td", null,
                        React.createElement("pre", { style: { textAlign: 'right' } }, row.code))))))))));
};
export default observer(RouteDemo);
