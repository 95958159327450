import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { message } from 'antd';
import { h3SetToMultiPolygon, h3ToGeoBoundary } from 'h3-js';
const hexPopup = (props) => {
    const labels = [];
    Object.keys(props).forEach(function (key) {
        labels.push(React.createElement(React.Fragment, null,
            React.createElement("strong", null,
                key,
                ":"),
            " ",
            props[key],
            React.createElement("br", null)));
    });
    return React.createElement("div", null, labels);
};
export const renderCumulativeSurface = (data, renderLayer) => {
    if (!data)
        return;
    renderLayer.clearLayers();
    renderLayer.addData(data);
    renderLayer.setStyle(styleScore);
    renderLayer.eachLayer((layer) => {
        layer.bindPopup(ReactDOMServer.renderToString(hexPopup(layer.feature.properties)));
    });
};
export const renderFileSurface = (data, renderLayer) => {
    if (!data)
        return;
    const fc = {
        type: 'FeatureCollection',
        features: Object.keys(data).map(hex => ({
            id: 'Surface',
            type: 'Feature',
            geometry: { type: 'Polygon', coordinates: [h3ToGeoBoundary(hex, true)] },
            properties: { score: data[hex] },
        })),
    };
    renderLayer.addData(fc);
    renderLayer.setStyle(styleScore);
};
export const renderSurface = (data, renderLayer) => {
    if (!data)
        return;
    const fc = {
        type: 'FeatureCollection',
        features: data.reduce((acc, current) => {
            const properties = current.props;
            const features = current.hexes.reduce((gj, currentHex) => {
                gj.push({
                    type: 'Feature',
                    geometry: {
                        type: 'Polygon',
                        coordinates: [h3ToGeoBoundary(currentHex, true)],
                    },
                    properties,
                });
                return gj;
            }, []);
            features.forEach(ft => acc.push(ft));
            return acc;
        }, []),
    };
    renderLayer.setStyle(styleSuitabilityNoHighlight);
    renderLayer.addData(fc);
    renderLayer.eachLayer((layer) => {
        layer.bindPopup(ReactDOMServer.renderToString(hexPopup(layer.feature.properties)), {
            maxHeight: 150,
            minWidth: 300,
        });
    });
};
export const renderSuitabilityV2 = (data, renderLayer) => {
    if (!data)
        return;
    const layers = Object.keys(data.bitmaskMap);
    if (layers.length >= 32) {
        throw new Error('bitmask too large for javascript - revamp this code to deal with anything greater than 32 bits. ' +
            'Alternatively what you should do is spend the time to implement the protobuf code, which is capable of doing it');
    }
    // Our goal here is to determine a fast lookup table for when we begin trying to paint
    // hexes on the map. The hexes will each have a bitmask, and the bitmask lookup is provided
    // in data.bitmaskMap. The bitmask map details several values that are computed via
    // 1 << i, where 0<=i<=32. E.g.
    // {grids: {bitmask: 4}, streams: {bitmask: 2}, eocs: {bitmask: 1}}
    //
    // We take these values and reverse their lookup so we can easily label hexes with their
    // source. The fastest way is via an array: [eocs, streams, grids].
    // Now if we need to answer the question "what is the bitmask value for eocs" we can
    // answer it based on the index in the array and plugging it in for variable i: 1 << i
    // becomes 1 << 0 = 1. Now when a hex comes by with bitmask 5, we can say "what is contained in this hex"
    // very easily by running a filter: reverseLookup.filter((_, i) => 1 << index & hex.bitmask)
    // Therefore 1 << 0 & 5 evals to true, 1 << 1 & 5 evals to false, 1 << 2 & 5 evals to true,
    // so this hex has "eocs" and "grids" within it. Resulting array is ["eocs", "grids"]
    const reverseLookup = layers.reduce((acc, current) => {
        var _a;
        const mask = (_a = data.bitmaskMap[current]) === null || _a === void 0 ? void 0 : _a.bitmask;
        if (mask)
            acc[Math.log2(mask)] = current;
        return acc;
    }, new Array(layers.length));
    const fc = {
        type: 'FeatureCollection',
        features: data.hexes.map(hex => ({
            type: 'Feature',
            geometry: { type: 'Polygon', coordinates: [h3ToGeoBoundary(hex.index, true)] },
            properties: {
                risk: hex.risk || 0,
                features: reverseLookup
                    .filter((_, index) => {
                    if (!hex.bitmask)
                        return false;
                    return (1 << index) & hex.bitmask;
                })
                    .join(', '),
            },
        })),
    };
    renderLayer.addData(fc);
    renderLayer.setStyle(styleSuitabilityNoHighlight);
    renderLayer.eachLayer((layer) => {
        layer.bindPopup(ReactDOMServer.renderToString(hexPopup(layer.feature.properties)));
    });
};
export const renderSuitabilitySurface = (data, renderLayer, isHighlightPreferredChecked) => {
    if (!data)
        return;
    renderLayer.addData(data);
    renderLayer.setStyle(isHighlightPreferredChecked ? styleSurfaceFunctionsWithHighlight : styleSuitabilityNoHighlight);
    renderLayer.eachLayer((layer) => {
        layer.bindPopup(ReactDOMServer.renderToString(hexPopup(layer.feature.properties)));
    });
};
export const renderRoute = (data, renderLayer) => {
    if (!data) {
        message.error('missing data to render on map');
        return;
    }
    else if (!data.route) {
        message.error('missing final route');
        return;
    }
    if (data.surface) {
        renderSuitabilitySurface({
            type: 'FeatureCollection',
            features: Object.keys(data.surface).map(hex => ({
                id: 'suitability',
                type: 'Feature',
                geometry: { type: 'Polygon', coordinates: [h3ToGeoBoundary(hex, true)] },
                properties: data.surface[hex],
            })),
        }, renderLayer, true);
    }
    renderLayer.addData({
        id: 'route',
        type: 'Feature',
        geometry: data.route.path,
        properties: { route: true },
    });
    if (data.network) {
        renderLayer.addData({
            id: 'network',
            type: 'Feature',
            geometry: data.network,
            properties: { network: true },
        });
    }
    if (data.corridor) {
        renderLayer.addData({
            type: 'FeatureCollection',
            features: [
                {
                    id: 'corridor',
                    type: 'Feature',
                    properties: { corridor: true },
                    geometry: {
                        type: 'MultiLineString',
                        coordinates: h3SetToMultiPolygon(Array.from(new Set(data.corridor)), true)[0],
                    },
                },
            ],
        });
    }
    renderLayer.setStyle(styleSurfaceFunctionsWithHighlight);
};
export const renderRouteV2 = (data, renderLayer) => {
    // Corridor, network, path
    if (!data) {
        message.error('missing data to render on map');
        return;
    }
    else if (!data.path) {
        message.error('missing final route');
        return;
    }
    if (data.surface) {
        renderSuitabilitySurface({
            type: 'FeatureCollection',
            features: Object.keys(data.surface).map(hex => ({
                id: 'suitability',
                type: 'Feature',
                geometry: { type: 'Polygon', coordinates: [h3ToGeoBoundary(hex, true)] },
                properties: data.surface[hex],
            })),
        }, renderLayer, true);
    }
    renderLayer.addData({
        id: 'route',
        type: 'Feature',
        geometry: data.path,
        properties: { route: true },
    });
    if (data.network) {
        renderLayer.addData({
            id: 'network',
            type: 'Feature',
            geometry: data.network,
            properties: { network: true },
        });
    }
    if (data.corridor) {
        renderLayer.addData({
            type: 'FeatureCollection',
            features: [
                {
                    id: 'corridor',
                    type: 'Feature',
                    properties: { corridor: true },
                    geometry: {
                        type: 'MultiLineString',
                        coordinates: h3SetToMultiPolygon(Array.from(new Set(data.corridor)), true)[0],
                    },
                },
            ],
        });
    }
    renderLayer.setStyle(styleSurfaceFunctionsWithHighlight);
};
export const renderDensitySurface = (choroLayer, renderLayer) => {
    if (!choroLayer)
        return;
    renderLayer.clearLayers();
    renderLayer.addLayer(choroLayer);
};
export const renderStrikeSurface = (data, renderLayer) => {
    if (!data)
        return;
    renderLayer.clearLayers();
    renderLayer.addData(data);
    renderLayer.setStyle(styleStrike);
    renderLayer.eachLayer((layer) => {
        const { popPerSqMi, failuresPerStrike, flightsPerStrike } = layer.feature.properties;
        // the flightsPerStrike property will not exist if there is 0 population in the hex
        if (flightsPerStrike) {
            const popupContent = `<strong>Pop Sq Mi:</strong> ${numberWithCommas(Math.round(popPerSqMi))}<br/>
      <strong>Failures Per Strike:</strong> ${numberWithCommas(Math.round(failuresPerStrike))}<br/>
      <strong>Flights Per Strike:</strong> ${numberWithCommas(Math.round(flightsPerStrike))}<br/>`;
            layer.bindPopup(popupContent);
        }
        else {
            const popupContent = `<strong>Pop Sq Mi:</strong> ${numberWithCommas(Math.round(popPerSqMi))}<br/>`;
            layer.bindPopup(popupContent);
        }
    });
};
export const renderWeatherSurface = (data, renderLayer, property, showTooltips, res) => {
    if (!data)
        return;
    renderLayer.clearLayers();
    renderLayer.addData(data);
    // Select proper weather styling function
    if (property === 'windSpeed') {
        renderLayer.setStyle(styleWeatherWindSpeed);
    }
    else if (property === 'temperature') {
        renderLayer.setStyle(styleWeatherTemperature);
    }
    else if (property === 'precipChance') {
        renderLayer.setStyle(styleWeatherPrecip);
    }
    let popupContent;
    let tooltipContent;
    renderLayer.eachLayer((layer) => {
        switch (property) {
            case 'windSpeed': {
                const windSpeed = layer.feature.properties['windSpeed'];
                popupContent = `<strong>Wind Speed:</strong> ${windSpeed}<br>
            <strong>Wind Direction:</strong> ${layer.feature.properties['windDirectionCardinal']}<br>`;
                tooltipContent = String(windSpeed);
                break;
            }
            case 'temperature': {
                const temperature = layer.feature.properties['temperature'];
                popupContent = `<strong>Temperature:</strong> ${temperature}<br>`;
                tooltipContent = String(temperature);
                break;
            }
            case 'precipChance': {
                const precipChance = layer.feature.properties['precipChance'];
                popupContent = `<strong>Precipitation Chance:</strong> ${precipChance}<br>`;
                tooltipContent = String(precipChance);
                break;
            }
        }
        layer.bindPopup(popupContent);
        // Only bind tooltips if we are going to show them (not res 10)
        if (res <= 8) {
            // Set static tooltip in center of hex
            // Close tooltips by default if button is not activated
            const tooltipOptions = {
                permanent: true,
                direction: 'center',
                className: 'weather-tooltip',
            };
            if (!showTooltips) {
                layer.bindTooltip(tooltipContent, tooltipOptions).closeTooltip();
            }
            else if (showTooltips) {
                layer.bindTooltip(tooltipContent, tooltipOptions);
            }
        }
    });
};
export const renderBlankHexes = (data, renderLayer) => {
    if (!data)
        return;
    renderLayer.addData(data);
    renderLayer.setStyle(styleBlankHexes);
};
export const renderFAAGrids = (data, renderLayer, showTooltips) => {
    if (!data)
        return;
    renderLayer.clearLayers();
    renderLayer.addData(data);
    renderLayer.setStyle(styleFAAGrids);
    let tooltipContent;
    renderLayer.eachLayer((layer) => {
        tooltipContent = String(layer.feature.properties['CEILING']);
        const tooltipOptions = {
            permanent: true,
            direction: 'center',
            className: 'weather-tooltip',
        };
        if (!showTooltips) {
            layer.bindTooltip(tooltipContent, tooltipOptions).closeTooltip();
        }
        else if (showTooltips) {
            layer.bindTooltip(tooltipContent, tooltipOptions);
        }
    });
};
export const renderGPSHexes = (data, renderLayer, gpsAGL) => {
    if (!data)
        return;
    renderLayer.clearLayers();
    renderLayer.addData(data);
    // Since we can't pass additional parameters into setStyle, using a different function
    // for each GPS AGL, as each is stored in a separate property
    switch (gpsAGL) {
        case 'dop_1m': {
            renderLayer.setStyle(styleGPS1m);
            renderLayer.eachLayer((layer) => {
                const { dop_1m } = layer.feature.properties;
                const popupContent = `<strong>Signal Strength:</strong> ${dop_1m}`;
                layer.bindPopup(popupContent);
            });
            break;
        }
        case 'dop_10m': {
            renderLayer.setStyle(styleGPS10m);
            renderLayer.eachLayer((layer) => {
                const { dop_10m } = layer.feature.properties;
                const popupContent = `<strong>Signal Strength:</strong> ${dop_10m}`;
                layer.bindPopup(popupContent);
            });
            break;
        }
        case 'dop_40m': {
            renderLayer.setStyle(styleGPS40m);
            renderLayer.eachLayer((layer) => {
                const { dop_40m } = layer.feature.properties;
                const popupContent = `<strong>Signal Strength:</strong> ${dop_40m}`;
                layer.bindPopup(popupContent);
            });
            break;
        }
    }
};
export const renderDetectionComplianceFAAGrids = (data, renderLayer) => {
    if (!data)
        return;
    renderLayer.clearLayers();
    renderLayer.addData(data);
    renderLayer.setStyle(styleFAAGrids);
    let popupContent;
    renderLayer.eachLayer((layer) => {
        popupContent = `<strong>Ceiling height:</strong> ${layer.feature.properties['CEILING']} ft`;
        layer.bindPopup(popupContent);
    });
};
export const renderDetectionComplianceRestricted = (data, renderLayer) => {
    if (!data)
        return;
    renderLayer.clearLayers();
    renderLayer.addData(data);
    renderLayer.setStyle(styleRestrictedAirspace);
    let popupContent;
    renderLayer.eachLayer((layer) => {
        popupContent = `<strong>Location:</strong> ${layer.feature.properties['Base']}`;
        layer.bindPopup(popupContent);
    });
};
export const renderDetectionCompliancePoints = (data, detectionPointLayer) => {
    if (!data)
        return;
    detectionPointLayer.clearLayers();
    detectionPointLayer.addData(data);
    detectionPointLayer.setStyle(() => {
        return {
            radius: 4,
            color: '#000000',
            fillColor: '#ed181b',
            weight: 1,
            opacity: 1,
            fillOpacity: 0.8,
        };
    });
    let popupContent;
    detectionPointLayer.eachLayer((layer) => {
        popupContent = `<strong>Height AGL:</strong> ${Math.round(layer.feature.properties['height'] * 3.28084)} ft<br/>
    <strong>Timestamp:</strong> ${layer.feature.properties['timestamp']}`;
        layer.bindPopup(popupContent);
    });
};
function styleScore(feature) {
    var _a;
    const score = (_a = feature === null || feature === void 0 ? void 0 : feature.properties) === null || _a === void 0 ? void 0 : _a.score;
    const scoreProps = { weight: 2, opacity: 0.5, fillOpacity: 0.7 };
    if (score <= 1)
        return Object.assign({ fillColor: '#68FFBE', color: '#68BE94' }, scoreProps);
    if (score <= 2.0)
        return Object.assign({ fillColor: '#1ad67c', color: '#1fb86f' }, scoreProps);
    if (score <= 2.5)
        return Object.assign({ fillColor: '#579447', color: '#7BA569' }, scoreProps);
    if (score <= 3)
        return Object.assign({ color: '#fcff40' }, scoreProps);
    if (score <= 3.5)
        return Object.assign({ color: '#ff9c19' }, scoreProps);
    if (score <= 4.0)
        return Object.assign({ color: '#FF7419' }, scoreProps);
    if (score <= 5.0)
        return Object.assign({ color: '#FF1A19' }, scoreProps);
    return {};
}
/**
 * @description Style surface-based features like routing
 * @param feature The geojson that's looped over for rendering
 */
function styleSurfaceFunctionsWithHighlight(feature) {
    var _a, _b;
    const props = feature === null || feature === void 0 ? void 0 : feature.properties;
    if (!props)
        return {};
    if (props.network)
        return { color: 'black', opacity: 0.4, weight: 0.7 };
    if (props.route)
        return { color: 'black', opacity: 1, weight: 4.2 };
    if (props.corridor)
        return { color: 'blue', fillOpacity: 0 };
    // At this point, the feature should be a hex, not one of the special routing
    // components
    const score = (_a = feature === null || feature === void 0 ? void 0 : feature.properties) === null || _a === void 0 ? void 0 : _a.score;
    const scoreProps = { weight: 2, opacity: 0.5, fillOpacity: 0.7 };
    const suitable = (_b = feature === null || feature === void 0 ? void 0 : feature.properties) === null || _b === void 0 ? void 0 : _b.suitableFeatures;
    if (suitable === null || suitable === void 0 ? void 0 : suitable.length)
        return { color: '#0090ff', weight: 2, opacity: 0.3, fillOpacity: 0.4 };
    if (score <= 1)
        return Object.assign({ fillColor: '#68FFBE', color: '#68BE94' }, scoreProps);
    if (score <= 2.0)
        return Object.assign({ fillColor: '#1ad67c', color: '#1fb86f' }, scoreProps);
    if (score <= 2.5)
        return Object.assign({ fillColor: '#579447', color: '#7BA569' }, scoreProps);
    if (score <= 3)
        return Object.assign({ color: '#fcff40' }, scoreProps);
    if (score <= 3.5)
        return Object.assign({ color: '#ff9c19' }, scoreProps);
    if (score <= 4.0)
        return Object.assign({ color: '#FF7419' }, scoreProps);
    if (score <= 5.0)
        return Object.assign({ color: '#FF1A19' }, scoreProps);
    return scoreProps;
}
function styleSuitabilityNoHighlight(feature) {
    var _a, _b;
    const score = ((_a = feature === null || feature === void 0 ? void 0 : feature.properties) === null || _a === void 0 ? void 0 : _a.score) || ((_b = feature === null || feature === void 0 ? void 0 : feature.properties) === null || _b === void 0 ? void 0 : _b.risk);
    const scoreProps = { weight: 2, opacity: 0.5, fillOpacity: 0.7 };
    if (score <= 1)
        return Object.assign({ fillColor: '#68FFBE', color: '#68BE94' }, scoreProps);
    if (score <= 2.0)
        return Object.assign({ fillColor: '#1ad67c', color: '#1fb86f' }, scoreProps);
    if (score <= 2.5)
        return Object.assign({ fillColor: '#579447', color: '#7BA569' }, scoreProps);
    if (score <= 3)
        return Object.assign({ color: '#fcff40' }, scoreProps);
    if (score <= 3.5)
        return Object.assign({ color: '#ff9c19' }, scoreProps);
    if (score <= 4.0)
        return Object.assign({ color: '#FF7419' }, scoreProps);
    if (score <= 5.0)
        return Object.assign({ color: '#FF1A19' }, scoreProps);
    return scoreProps;
}
function styleStrike(feature) {
    var _a;
    const fps = (_a = feature === null || feature === void 0 ? void 0 : feature.properties) === null || _a === void 0 ? void 0 : _a.flightsPerStrike;
    const fpsProps = { weight: 1, opacity: 0.4, fillOpacity: 0.5, color: '#000000' };
    // the flightsPerStrike property will not exist if there is 0 population in the hex
    if (fps) {
        if (fps <= 1000000) {
            return Object.assign({ fillColor: '#f01805' }, fpsProps);
        }
        else if (fps <= 2000000) {
            return Object.assign({ fillColor: '#ed7f11' }, fpsProps);
        }
        else if (fps <= 10000000) {
            return Object.assign({ fillColor: '#e3e036' }, fpsProps);
        }
        else if (fps <= 50000000) {
            return Object.assign({ fillColor: '#7BC069' }, fpsProps);
        }
        else if (fps <= 1e100) {
            return Object.assign({ fillColor: '#7BEE9F' }, fpsProps);
        }
    }
    return Object.assign({ fillColor: '#FFFFFF' }, fpsProps);
}
function styleWeatherWindSpeed(feature) {
    var _a;
    const windspeed = (_a = feature === null || feature === void 0 ? void 0 : feature.properties) === null || _a === void 0 ? void 0 : _a.windSpeed;
    const windSpeedProps = { weight: 1, opacity: 0.3, fillOpacity: 0.7 };
    if (windspeed <= 5) {
        return Object.assign({ fillColor: '#7BEE9F' }, windSpeedProps);
    }
    else if (windspeed <= 10) {
        return Object.assign({ fillColor: '#7BC069' }, windSpeedProps);
    }
    else if (windspeed <= 30) {
        return Object.assign({ fillColor: '#e3e036' }, windSpeedProps);
    }
    else if (windspeed <= 50) {
        return Object.assign({ fillColor: '#ed7f11' }, windSpeedProps);
    }
    else if (windspeed <= 100) {
        return Object.assign({ fillColor: '#f01805' }, windSpeedProps);
    }
    return {};
}
function styleWeatherTemperature(feature) {
    var _a;
    const temperature = (_a = feature === null || feature === void 0 ? void 0 : feature.properties) === null || _a === void 0 ? void 0 : _a.temperature;
    const temperatureProps = { weight: 1, opacity: 0.3, fillOpacity: 0.6 };
    if (temperature <= 0) {
        return Object.assign({ fillColor: '#0004ff' }, temperatureProps);
    }
    else if (temperature <= 32) {
        return Object.assign({ fillColor: '#3bc7d1' }, temperatureProps);
    }
    else if (temperature <= 60) {
        return Object.assign({ fillColor: '#60d1b5' }, temperatureProps);
    }
    else if (temperature <= 90) {
        return Object.assign({ fillColor: '#f7c211' }, temperatureProps);
    }
    else {
        return Object.assign({ fillColor: '#db6e00' }, temperatureProps);
    }
}
function styleWeatherPrecip(feature) {
    var _a;
    const precip = (_a = feature === null || feature === void 0 ? void 0 : feature.properties) === null || _a === void 0 ? void 0 : _a.precipChance;
    if (precip == 0) {
        return { fillColor: '#ffffff', weight: 1, opacity: 0.3, fillOpacity: 0.3 };
    }
    else if (precip <= 50) {
        return { fillColor: '#2874de', weight: 1, opacity: 0.6, fillOpacity: 0.2 };
    }
    else {
        return { fillColor: '#006aff', weight: 1, opacity: 0.6, fillOpacity: 0.5 };
    }
}
export function styleCompliancePoint(feature) {
    const compliance = feature.properties['is_compliant'];
    if (compliance) {
        return {
            radius: 6,
            color: '#000000',
            fillColor: '#66ff7f',
            weight: 1,
            opacity: 1,
            fillOpacity: 0.8,
        };
    }
    else {
        return {
            radius: 6,
            color: '#000000',
            fillColor: '#ed181b',
            weight: 1,
            opacity: 1,
            fillOpacity: 0.8,
        };
    }
}
function styleBlankHexes() {
    return { fillColor: '#fcfcfc', color: '#000000', weight: 0.4, opacity: 0.5, fillOpacity: 0.05 };
}
function styleFAAGrids() {
    return { fillColor: '#EB5757', color: '#9e110e', weight: 0.4, opacity: 1, fillOpacity: 0.2 };
}
function styleRestrictedAirspace() {
    return { fillColor: '#ff2424', color: '#9e110e', weight: 0.8, opacity: 1, fillOpacity: 0.7 };
}
function styleGPS1m(feature) {
    var _a;
    const strength = (_a = feature === null || feature === void 0 ? void 0 : feature.properties) === null || _a === void 0 ? void 0 : _a.dop_1m;
    const gpsProps = { weight: 2, opacity: 0.5, fillOpacity: 0.7 };
    switch (strength) {
        case 'NONE_SPECIFIED': {
            return Object.assign({ fillColor: '#68FFBE', color: '#68BE94' }, gpsProps);
        }
        case 'EXCELLENT': {
            return Object.assign({ fillColor: '#68FFBE', color: '#68BE94' }, gpsProps);
        }
        case 'GOOD': {
            return Object.assign({ fillColor: '#1ad67c', color: '#1fb86f' }, gpsProps);
        }
        case 'MODERATE': {
            return Object.assign({ fillColor: '#579447', color: '#7BA569' }, gpsProps);
        }
        case 'FAIR': {
            return Object.assign({ color: '#fcff40' }, gpsProps);
        }
        case 'POOR': {
            return Object.assign({ color: '#ff9c19' }, gpsProps);
        }
        case 'NO_DOP': {
            return Object.assign({ color: '#FF1A19' }, gpsProps);
        }
        default:
            // Need this because the StyleFunction interface can't handle undefined, but empty object is ok
            return { opacity: 0, fillOpacity: 0 };
    }
}
function styleGPS10m(feature) {
    var _a;
    const strength = (_a = feature === null || feature === void 0 ? void 0 : feature.properties) === null || _a === void 0 ? void 0 : _a.dop_10m;
    const gpsProps = { weight: 2, opacity: 0.5, fillOpacity: 0.7 };
    switch (strength) {
        case 'NONE_SPECIFIED': {
            return Object.assign({ fillColor: '#68FFBE', color: '#68BE94' }, gpsProps);
        }
        case 'EXCELLENT': {
            return Object.assign({ fillColor: '#68FFBE', color: '#68BE94' }, gpsProps);
        }
        case 'GOOD': {
            return Object.assign({ fillColor: '#1ad67c', color: '#1fb86f' }, gpsProps);
        }
        case 'MODERATE': {
            return Object.assign({ fillColor: '#579447', color: '#7BA569' }, gpsProps);
        }
        case 'FAIR': {
            return Object.assign({ color: '#fcff40' }, gpsProps);
        }
        case 'POOR': {
            return Object.assign({ color: '#ff9c19' }, gpsProps);
        }
        case 'NO_DOP': {
            return Object.assign({ color: '#FF1A19' }, gpsProps);
        }
        default:
            // Need this because the StyleFunction interface can't handle undefined, but empty object is ok
            return { opacity: 0, fillOpacity: 0 };
    }
}
function styleGPS40m(feature) {
    var _a;
    const strength = (_a = feature === null || feature === void 0 ? void 0 : feature.properties) === null || _a === void 0 ? void 0 : _a.dop_40m;
    const gpsProps = { weight: 2, opacity: 0.5, fillOpacity: 0.7 };
    switch (strength) {
        case 'NONE_SPECIFIED': {
            return Object.assign({ fillColor: '#68FFBE', color: '#68BE94' }, gpsProps);
        }
        case 'EXCELLENT': {
            return Object.assign({ fillColor: '#68FFBE', color: '#68BE94' }, gpsProps);
        }
        case 'GOOD': {
            return Object.assign({ fillColor: '#1ad67c', color: '#1fb86f' }, gpsProps);
        }
        case 'MODERATE': {
            return Object.assign({ fillColor: '#579447', color: '#7BA569' }, gpsProps);
        }
        case 'FAIR': {
            return Object.assign({ color: '#fcff40' }, gpsProps);
        }
        case 'POOR': {
            return Object.assign({ color: '#ff9c19' }, gpsProps);
        }
        case 'NO_DOP': {
            return Object.assign({ color: '#FF1A19' }, gpsProps);
        }
        default:
            return { opacity: 0, fillOpacity: 0 };
    }
}
function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
