import React from 'react';
import styled from 'styled-components';
import DemoPicker from './DemoPicker';
const Wrapper = styled.div `
  position: absolute;
  top: 1%;
  right: 1%;
  padding: 20px;
  overflow-y: auto;
  max-height: 98%;
  background: white;
  z-index: 1000;
  border-radius: 5px;
  -webkit-box-shadow: 0 5px 10px -1px rgba(0, 0, 0, 0.21);
  box-shadow: 0 5px 10px -1px rgba(0, 0, 0, 0.21);
`;
const InputContainer = () => (React.createElement(Wrapper, null,
    React.createElement(DemoPicker, null)));
// This component is a mobx observer. This means it will observe and react
// to changes of any mobx object.
export default InputContainer;
