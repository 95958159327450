import { types } from 'mobx-state-tree';
export const Operation = types
    .model('Operation', {
    maxAltitude: types.optional(types.number, 100),
    losType: types.optional(types.enumeration(['VLOS', 'EVLOS', 'BVLOS']), 'BVLOS'),
    pilotControl: types.optional(types.enumeration(['SINGLE_PILOT', 'MULTIPLE_PILOT', 'AUTOMATED_CONTROL']), 'SINGLE_PILOT'),
    uavType: types.optional(types.enumeration(['FIXED_WING', 'HELICOPTER', 'MULTIROTOR', 'HYBRID']), 'HYBRID'),
    uavWeight: types.optional(types.enumeration(['MICRO', 'MINI', 'LIMITED', 'BANTAM']), 'LIMITED'),
    resolution: types.optional(types.number, 9),
})
    .actions(self => {
    const setMaxAltitude = (value) => {
        self.maxAltitude = value;
    };
    const setLOSType = (value) => {
        self.losType = value;
    };
    const setPilotControl = (value) => {
        self.pilotControl = value;
    };
    const setUAVType = (value) => {
        self.uavType = value;
    };
    const setUAVWeight = (value) => {
        self.uavWeight = value;
    };
    const setResolution = (value) => {
        self.resolution = value;
    };
    return {
        setMaxAltitude,
        setLOSType,
        setPilotControl,
        setUAVType,
        setUAVWeight,
        setResolution,
    };
});
