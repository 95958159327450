var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { Button, Modal, message, Form, InputNumber, Divider } from 'antd';
import { useAppContext } from 'stores/AppStore';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { getPopulationDensity } from 'service/DataService';
import { ShapeRadioFormItem } from 'components/SharedComponents';
const DensityDemo = () => {
    // The useForm hook is an AntD hook
    const [form] = Form.useForm();
    // Import the operation and map stores into this component
    // These stores are loaded from the context created in AppStore
    const { operationStore, mapStore, accessToken } = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    const local = useLocalObservable(() => ({
        initBreakValues: [100, 3000, 7000, 10000, 14000, 20000],
        formListSize: 6,
    }));
    // Every input from the form that has a 'name' property will be
    // passed back to this event object
    const handleOnFinish = (event) => __awaiter(void 0, void 0, void 0, function* () {
        if (!mapStore.sketch) {
            Modal.info({
                title: 'Please add sketch',
                content: 'Processing may not proceed until you complete a sketch',
            });
            return;
        }
        // Create array of numbers from the form list
        // (there is probably a better way to do this)
        const populationClassMaximums = event.breakValues.map((breakpoint) => breakpoint);
        // List needs to be in ascending order for the Population data service to work properly
        populationClassMaximums.sort((a, b) => a - b);
        // Construct a function payload object
        const payload = {
            coordinates: mapStore.sketch.features[0].geometry.coordinates,
            populationClassMaximums: populationClassMaximums,
        };
        // Request the surface
        setIsLoading(true);
        const result = yield getPopulationDensity(payload, accessToken).catch(ex => {
            message.error(ex.message);
        });
        if (result) {
            const choroLayer = mapStore.makePopChoroplethLayer(result, local.formListSize);
            mapStore.renderData(choroLayer, 'DENSITY', operationStore.operation.resolution);
        }
        setIsLoading(false);
    });
    return (React.createElement(Form, { className: 'compressed-form', form: form, layout: 'vertical', style: { width: '100%' }, onFinish: handleOnFinish },
        React.createElement(Form.List, { name: 'breakValues', initialValue: local.initBreakValues }, (fields, { add, remove }) => (React.createElement(React.Fragment, null,
            React.createElement(Form.Item, { label: '# of classes', name: 'numClasses' },
                React.createElement(InputNumber, { onChange: (e) => {
                        const diff = e - fields.length;
                        if (diff > 0) {
                            for (let i = 0; i < diff; i++) {
                                add();
                            }
                        }
                        else if (diff < 0) {
                            for (let i = 0; i > diff; i--) {
                                remove(fields.length - 1);
                            }
                        }
                        local.formListSize = fields.length;
                    }, defaultValue: fields.length, min: 0 }),
                React.createElement(Divider, { orientation: 'left', style: { fontSize: 'small' } },
                    "Population Classification Breaks",
                    React.createElement("br", null),
                    "(people/mi2)"),
                fields.map((field, index) => (React.createElement("div", { style: { display: 'flex', alignItems: 'center' }, key: field.key },
                    React.createElement(Form.Item, Object.assign({}, field, { rules: [{ required: true }] }),
                        React.createElement(InputNumber, null)),
                    React.createElement("span", { style: { marginLeft: '12px', fontSize: 'small' } },
                        " Class ",
                        index + 1)))))))),
        React.createElement(ShapeRadioFormItem, { onClick: (e) => __awaiter(void 0, void 0, void 0, function* () { return yield mapStore.startSketch(e.target.defaultValue); }), label: 'Shape' }),
        React.createElement(Form.Item, { style: { margin: '20px 0 0 0' } },
            React.createElement(Button, { type: 'primary', htmlType: 'submit', loading: isLoading }, "Submit"))));
};
export default observer(DensityDemo);
