var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { Form, Button, Modal, message, Alert, Input } from 'antd';
import { useAppContext } from 'stores/AppStore';
import { getSnapshot } from 'mobx-state-tree';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { getSurfaceV2 } from 'service/DataService';
import { ResolutionSliderFormItem, ShapeRadioFormItem } from 'components/SharedComponents';
// Intialize data service result here so we can reuse it when re-rendering
let result;
const defaultRequest = `[\n{"code": "roads", "fields": ["*"]}\n]`;
const SurfaceDemo = () => {
    // The useForm hook is an AntD hook
    const [form] = Form.useForm();
    // Import the operation and map stores into this component
    // These stores are loaded from the context created in AppStore
    const { operationStore, mapStore, accessToken } = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    const [layersPayload, setLayersPayload] = useState(defaultRequest);
    const local = useLocalObservable(() => ({
        suitabilityWeight: 0.7,
        changeSuitabilityWeight(value) {
            local.suitabilityWeight = value;
        },
        setHighlightPreferred(e) {
            mapStore.setHighlightPreferred(e);
            mapStore.renderData(result === null || result === void 0 ? void 0 : result.data, 'SUITABILITY', operationStore.operation.resolution);
        },
    }));
    // Every input from the form that has a 'name' property will be
    // passed back to this values object
    const handleOnFinish = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        if (!mapStore.sketch) {
            Modal.info({
                title: 'Please add sketch',
                content: 'Processing may not proceed until you complete a sketch',
            });
            return;
        }
        if (!layersPayload) {
            message.error('Layers payload is required');
            return;
        }
        let layers;
        try {
            layers = JSON.parse(layersPayload);
        }
        catch (e) {
            message.error(`Suitable features payload invalid: ${e.toString()}`);
            console.error(e);
            return;
        }
        if (!(layers instanceof Array))
            message.error('Layers payload must be an array');
        // Construct a function payload object
        const payload = {
            geometry: mapStore.sketch.features[0].geometry,
            layers,
            resolution: operationStore.operation.resolution,
        };
        // Request the surface
        setIsLoading(true);
        try {
            mapStore.clearEverything();
            result = yield getSurfaceV2(payload, accessToken);
            mapStore.renderData(result === null || result === void 0 ? void 0 : result.data, 'SURFACE', operationStore.operation.resolution);
        }
        catch (e) {
            message.error((_a = e) === null || _a === void 0 ? void 0 : _a.message);
        }
        finally {
            setIsLoading(false);
        }
    });
    return (React.createElement(Form, { className: 'compressed-form', form: form, layout: 'vertical', style: { width: '100%' }, onFinish: handleOnFinish, initialValues: getSnapshot(operationStore.operation) },
        React.createElement(Alert, { showIcon: true, message: 'In beta testing', description: 'This demo may be unstable', type: 'warning' }),
        React.createElement(ResolutionSliderFormItem, null),
        React.createElement(Form.Item, { label: 'Layers payload' },
            React.createElement(Input.TextArea, { defaultValue: defaultRequest, onChange: e => setLayersPayload(e.target.value) })),
        React.createElement(ShapeRadioFormItem, { onClick: (e) => __awaiter(void 0, void 0, void 0, function* () { return yield mapStore.startSketch(e.target.defaultValue); }), label: 'Shape' }),
        React.createElement(Form.Item, { style: { margin: '15px 0 0 0' } },
            React.createElement(Button, { type: 'primary', htmlType: 'submit', loading: isLoading }, "Submit"))));
};
export default observer(SurfaceDemo);
