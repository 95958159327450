import React, { useEffect } from 'react';
import { useAppContext } from 'stores/AppStore';
const Map = () => {
    const { mapStore } = useAppContext();
    // https://reactjs.org/docs/hooks-effect.html
    // useEffect tells react that your component needs to do something after it renders
    // In other words, after the DOM has rendered the <div>, useEffect will run.
    // In this case, we want to create a map from the mobx MapStore
    useEffect(() => {
        // When the component loads, create the map
        mapStore.createMap('map');
        // Return a cleanup function if the map hot-reloads
        return () => {
            mapStore.cleanup();
        };
    }, []);
    return React.createElement("div", { id: 'map' });
};
export default Map;
