import React from 'react';
import { Select, Slider, Form, Radio } from 'antd';
import { useAppContext } from 'stores/AppStore';
const { Option } = Select;
export const UAVTypeSelectFormItem = ({ defaultValue, onChange, }) => {
    return (React.createElement(Form.Item, { label: 'UAV Type', name: 'uavType' },
        React.createElement(Select, { onChange: onChange, defaultValue: defaultValue },
            React.createElement(Option, { value: 'FIXED_WING' }, "Fixed Wing"),
            React.createElement(Option, { value: 'HELICOPTER' }, "Helicopter"),
            React.createElement(Option, { value: 'MULTIROTOR' }, "Multirotor"),
            React.createElement(Option, { value: 'HYBRID' }, "Hybrid"))));
};
export const UAVWeightSelectFormItem = ({ defaultValue, onChange, }) => {
    return (React.createElement(Form.Item, { label: 'UAV Weight', name: 'uavWeight' },
        React.createElement(Select, { onChange: onChange, defaultValue: defaultValue },
            React.createElement(Option, { value: 'MICRO' }, "Micro"),
            React.createElement(Option, { value: 'MINI' }, "Mini"),
            React.createElement(Option, { value: 'LIMITED' }, "Limited"),
            React.createElement(Option, { value: 'BANTAM' }, "Bantam"))));
};
export const LOSTypeSelectFormItem = ({ defaultValue, onChange, }) => {
    return (React.createElement(Form.Item, { label: 'Operation Type', name: 'losType' },
        React.createElement(Select, { onChange: onChange, defaultValue: defaultValue },
            React.createElement(Option, { value: 'VLOS' }, "VLOS"),
            React.createElement(Option, { value: 'EVLOS' }, "EVLOS"),
            React.createElement(Option, { value: 'BVLOS' }, "BVLOS"))));
};
export const PilotControlSelectFormItem = ({ defaultValue, onChange, }) => {
    return (React.createElement(Form.Item, { label: 'Pilot Control', name: 'pilotControl' },
        React.createElement(Select, { onChange: onChange, defaultValue: defaultValue },
            React.createElement(Option, { value: 'SINGLE_PILOT' }, "Single Pilot"),
            React.createElement(Option, { value: 'MULTIPLE_PILOT' }, "Multiple Pilots"),
            React.createElement(Option, { value: 'AUTOMATED_CONTROL' }, "Autonomous"))));
};
export const MaxAltitudeSliderFormItem = ({ defaultValue, onChange, }) => {
    return (React.createElement(Form.Item, { label: `Altitude (${defaultValue}' AGL)`, name: 'maxAltitude' },
        React.createElement(Slider, { min: 100, max: 400, step: 10, onChange: onChange, defaultValue: defaultValue })));
};
export const ResolutionSliderFormItem = ({ defaultValue, onChange, }) => {
    const { operationStore } = useAppContext();
    return (React.createElement(Form.Item, { label: `Resolution (${operationStore.operation.resolution})` },
        React.createElement(Slider, { min: 7, max: 11, step: 1, onChange: onChange || operationStore.operation.setResolution, defaultValue: defaultValue || operationStore.operation.resolution })));
};
export const ShapeRadioFormItem = ({ onClick, label, }) => {
    return (React.createElement(Form.Item, { label: label },
        React.createElement(Radio.Group, null,
            React.createElement(Radio.Button, { value: 'circle', onClick: onClick },
                React.createElement("i", { className: 'fal fa-draw-circle' })),
            React.createElement(Radio.Button, { value: 'rectangle', onClick: onClick },
                React.createElement("i", { className: 'fal fa-draw-square' })),
            React.createElement(Radio.Button, { value: 'polygon', onClick: onClick },
                React.createElement("i", { className: 'fal fa-draw-polygon' })))));
};
export const LineRadioFormItem = ({ onClick, label, }) => (React.createElement(Form.Item, { label: label },
    React.createElement(Radio.Group, null,
        React.createElement(Radio.Button, { value: 'line', onClick: onClick },
            React.createElement("i", { className: 'fal fa-pencil-alt' })))));
