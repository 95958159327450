var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { Button, Modal, message, Form, Select, DatePicker, Switch } from 'antd';
import moment from 'moment';
import { useAppContext } from 'stores/AppStore';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { getWeatherSurface } from 'service/DataService';
import { ResolutionSliderFormItem, ShapeRadioFormItem } from 'components/SharedComponents';
const { Option } = Select;
const dateFormat = 'YYYY-MM-DD HH:mm';
// Match the property actual name to a formatted name for the render function
const weatherTypeMap = {
    windSpeed: 'WEATHER-WIND',
    temperature: 'WEATHER-TEMP',
    precipChance: 'WEATHER-PRECIP',
};
// Initialize weather data service result
// Storing it outside all functions because it's reused when selecting new prop
let result;
const WeatherDemo = () => {
    // The useForm hook is an AntD hook
    const [form] = Form.useForm();
    // Import the operation and map stores into this component
    // These stores are loaded from the context created in AppStore
    const { operationStore, mapStore, accessToken } = useAppContext();
    const local = useLocalObservable(() => ({
        isLoading: false,
        // Always store datetime as a Moment object, convert to UNIX timestamp before submitting request to data service
        datetime: moment(),
        property: 'windSpeed',
        sketch: mapStore.sketch,
        tooltipSwitchDisabled: operationStore.operation.resolution >= 9 ? true : false,
        isTooltipChecked: false,
        requestedHexRes: Number(),
        toggleLoading() {
            local.isLoading = !local.isLoading;
        },
        toggleTooltip(e) {
            local.isTooltipChecked = e;
            mapStore.setShowTooltips(e);
        },
        setDatetime(value) {
            local.datetime = value;
        },
        setProperty(value) {
            local.property = value;
            mapStore.renderData(result, weatherTypeMap[local.property], operationStore.operation.resolution);
        },
        controlTooltipSwitch(res) {
            operationStore.operation.setResolution(res);
            if (operationStore.operation.resolution >= 9 || local.requestedHexRes >= 9) {
                local.tooltipSwitchDisabled = true;
                local.isTooltipChecked = false;
                mapStore.setShowTooltips(false);
            }
            else {
                local.tooltipSwitchDisabled = false;
            }
        },
    }));
    // Every input from the form that has a 'name' property will be
    // passed back to this values object
    const handleOnFinish = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!mapStore.sketch) {
            Modal.info({
                title: 'Please add sketch',
                content: 'Processing may not proceed until you complete a sketch',
            });
            return;
        }
        // Keep track of the current hex resolution so we can disable the tooltip slider
        local.requestedHexRes = operationStore.operation.resolution;
        local.controlTooltipSwitch(local.requestedHexRes);
        // Construct a function payload object
        const payload = {
            coords: mapStore.sketch.features[0].geometry.coordinates,
            resolution: operationStore.operation.resolution,
            // Send Moment date object as epoch (seconds)
            ztime: local.datetime.unix(),
            dataTimeLevel: 'hourData',
        };
        // Request the surface
        local.toggleLoading();
        result = yield getWeatherSurface(payload, accessToken).catch(ex => {
            message.error(ex.message);
        });
        mapStore.renderData(result, weatherTypeMap[local.property], operationStore.operation.resolution);
        local.toggleLoading();
    });
    return (React.createElement(Form, { className: 'compressed-form', form: form, layout: 'vertical', style: { width: '100%' }, onFinish: handleOnFinish, initialValues: local },
        React.createElement(Form.Item, { label: 'Date', name: 'datetime' },
            React.createElement(DatePicker, { format: dateFormat, showTime: { format: 'HH:mm' }, onChange: (value) => local.setDatetime(value), disabledDate: (currentDate) => currentDate.isBefore(moment(), 'day') })),
        React.createElement(Form.Item, { label: 'Property', name: 'property' },
            React.createElement(Select, { onChange: (value) => local.setProperty(value) },
                React.createElement(Option, { value: 'windSpeed' }, "Wind Speed (mph)"),
                React.createElement(Option, { value: 'temperature' }, "Temperature (F)"),
                React.createElement(Option, { value: 'precipChance' }, "Precip Chance"))),
        React.createElement(ResolutionSliderFormItem, { onChange: (res) => local.controlTooltipSwitch(res) }),
        React.createElement(Form.Item, { label: 'Overlay weather values', tooltip: 'Disabled for res 9 and higher' },
            React.createElement(Switch, { checked: local.isTooltipChecked, onChange: e => local.toggleTooltip(e), disabled: local.tooltipSwitchDisabled })),
        React.createElement(ShapeRadioFormItem, { onClick: (e) => __awaiter(void 0, void 0, void 0, function* () { return yield mapStore.startSketch(e.target.defaultValue); }), label: 'Shape' }),
        React.createElement(Form.Item, { style: { margin: '20px 0 0 0' } },
            React.createElement(Button, { type: 'primary', htmlType: 'submit', loading: local.isLoading }, "Submit"))));
};
export default observer(WeatherDemo);
