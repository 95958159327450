var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import * as turf from '@turf/turf';
import { Form, Button, Modal, message } from 'antd';
import { useAppContext } from 'stores/AppStore';
import { getSnapshot } from 'mobx-state-tree';
import { observer } from 'mobx-react-lite';
import { getElevationProfile, getGrids } from 'service/DataService';
import { MaxAltitudeSliderFormItem, LineRadioFormItem } from 'components/SharedComponents';
import { drawChart } from 'components/charts/ElevationProfileChart';
// Intialize data service result here so we can reuse it when re-rendering
let result;
let altitude;
const ElevationProfile = () => {
    const [form] = Form.useForm();
    const store = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        if (store.elevationChartVisible)
            drawChart(result, altitude, store.mapStore.addPointMarker, store.mapStore.clearPointMarker);
    }, [store.elevationChartVisible]);
    // Every input from the form that has a 'name' property will be
    // passed back to this values object
    const handleOnFinish = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!store.mapStore.sketch) {
            Modal.info({
                title: 'Please add sketch',
                content: 'Processing may not proceed until you complete a sketch',
            });
            return;
        }
        // get route length in miles
        const routeLength = turf.length(store.mapStore.sketch.features[0], { units: 'miles' });
        // route will be split in segments at a certain distance, based on the length in miles
        const segmentDistance = routeLength < 1
            ? 5
            : routeLength < 5
                ? 8
                : routeLength < 10
                    ? 25
                    : routeLength < 15
                        ? 40
                        : routeLength < 25
                            ? 60
                            : 100;
        // Construct a function payload object
        // Now using the CARS function to get the elevation (instead of the ESRI service)
        const payload = Object.assign({
            zUnits: 'ft',
            inVDatum: 'ellipsoid',
            profile: true,
            profileMethod: { meters: segmentDistance },
            geometry: store.mapStore.sketch.features[0].geometry,
        });
        // Request the elevation profile data from CARS-Elevator function
        setIsLoading(true);
        altitude = getSnapshot(store.operationStore.operation).maxAltitude;
        const initialResult = yield getElevationProfile(payload, store.accessToken).catch(ex => {
            message.error(ex.message);
        });
        // get UASFM grids that intersect the route
        // linting error but works well, spend way too much time to try to please Typestript checks
        const payloadFAA = Object.assign({
            // @ts-ignore:next-line
            paths: [store.mapStore.sketch.features[0].geometry.coordinates],
        });
        const grids = yield getGrids(payloadFAA);
        // tag the points along the route with the Ceiling value from the UASFM grids
        const tagged = turf.tag(initialResult['data'], grids, 'CEILING', 'Ceiling');
        // if no grid intersects the points, there is no Ceiling value - set a default value of 400 ft (max for drone to fly)
        for (let i = 0; i < tagged.features.length; i++) {
            // @ts-ignore:next-line
            if (typeof tagged.features[i].properties['Ceiling'] == 'undefined') {
                // @ts-ignore:next-line
                tagged.features[i].properties['Ceiling'] = 400;
            }
        }
        // get final array in the desired format
        result = tagged === null || tagged === void 0 ? void 0 : tagged.features.map(object => {
            return Object.assign(Object.assign({}, object.properties), { In_X: object.geometry.coordinates[0], In_Y: object.geometry.coordinates[1] });
        });
        // render the UASFM grids on the map
        store.setElevationChartVisible(false);
        store.mapStore.renderData(grids, 'ELEVATION-PROFILE', store.operationStore.operation.resolution);
        store.setElevationChartVisible(true);
        setIsLoading(false);
    });
    return (React.createElement(Form, { className: 'compressed-form', form: form, layout: 'vertical', style: { width: '100%' }, onFinish: handleOnFinish, initialValues: getSnapshot(store.operationStore.operation) },
        React.createElement(MaxAltitudeSliderFormItem, { defaultValue: store.operationStore.operation.maxAltitude, onChange: store.operationStore.operation.setMaxAltitude }),
        React.createElement(LineRadioFormItem, { onClick: (e) => __awaiter(void 0, void 0, void 0, function* () { return yield store.mapStore.startSketch(e.target.defaultValue); }), label: 'Edit route' }),
        React.createElement(Form.Item, { style: { margin: '15px 0 0 0' } },
            React.createElement(Button, { type: 'primary', htmlType: 'submit', loading: isLoading }, "Submit"))));
};
export default observer(ElevationProfile);
